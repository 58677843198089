import React, { FC } from 'react';
import { FormikHandlers, FormikHelpers, FormikState, getIn } from 'formik';

import { Box, Flex } from 'components/styled';
import TextInput from 'components/form/input/TextInput';
import RemoteSelect from 'components/form/select/RemoteSelect';

import { useMunicipalities } from 'api/municipalityApi';
import { useRegions } from 'api/regionApi';
import { useCountries } from 'api/countryApi';

import { municipalityLabelWithRegion } from 'utils/address';

import useAddressInputHandlers from './useAddressInputHandlers';

import { vsb } from 'typing/endpoints';

type Props = {
	attr: string;
	loadingAres: boolean;
	wasSubmitted: boolean;
} & Pick<FormikHelpers<vsb.Address>, 'setFieldValue' | 'setFieldTouched'> &
	Pick<FormikState<vsb.Address>, 'values' | 'isSubmitting'> &
	Partial<Pick<FormikState<vsb.Address>, 'touched' | 'errors'>> &
	Pick<FormikHandlers, 'handleChange' | 'handleBlur'>;

const CompanyAddressInput: FC<Props> = ({
	attr,
	values,
	errors,
	touched,
	handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
	wasSubmitted,
	isSubmitting,
	loadingAres,
}) => {
	const {
		isCzechAddress,
		onSetCountry,
		onSetRegion,
		onSetMunicipality,
		municipalitiesFilter,
	} = useAddressInputHandlers(attr, values, setFieldValue);

	return (
		<>
			<Flex width="100%" flexWrap="wrap">
				<Box width={[1, 1 / 2]} pr={[0, 4]} mb={3}>
					<TextInput
						id={`${attr}.street`}
						name={`${attr}.street`}
						label="Ulice"
						labelType="aboveInput"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.street}
						error={getIn(errors, 'street')}
						touched={wasSubmitted || getIn(touched, 'street')}
						disabled={isSubmitting || loadingAres}
					/>
				</Box>
				<Box width={[1 / 2, 1 / 4]} pr={3} mb={3}>
					<TextInput
						id={`${attr}.descriptiveNumber`}
						name={`${attr}.descriptiveNumber`}
						label="Č. popisné"
						labelType="aboveInput"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.descriptiveNumber}
						error={getIn(errors, 'descriptiveNumber')}
						touched={wasSubmitted || getIn(touched, 'descriptiveNumber')}
						disabled={isSubmitting || loadingAres}
					/>
				</Box>
				<Box width={[1 / 2, 1 / 4]} pl={3} mb={3}>
					<TextInput
						id={`${attr}.orientationNumber`}
						name={`${attr}.orientationNumber`}
						label="Č. orientační"
						labelType="aboveInput"
						required
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.orientationNumber}
						error={getIn(errors, 'orientationNumber')}
						touched={wasSubmitted || getIn(touched, 'orientationNumber')}
						disabled={isSubmitting || loadingAres}
					/>
				</Box>
			</Flex>

			<Flex width="100%">
				<Box width={[2 / 3, 1 / 2]} pr={4} mb={3}>
					{isCzechAddress ? (
						<RemoteSelect
							apiCall={useMunicipalities}
							filter={municipalitiesFilter}
							id={`${attr}.municipality`}
							label="Město"
							labelType="aboveInput"
							required
							placeholder="Zvolte město"
							onSetValue={onSetMunicipality}
							onSetTouched={setFieldTouched}
							value={values.municipality}
							error={getIn(errors, 'municipality')}
							touched={wasSubmitted || getIn(touched, 'municipality')}
							disabled={isSubmitting || loadingAres}
							labelFromOption={v =>
								values.region ? v?.name ?? '' : municipalityLabelWithRegion(v)
							}
						/>
					) : (
						<TextInput
							id={`${attr}.municipalityForeign`}
							name={`${attr}.municipalityForeign`}
							label="Město"
							labelType="aboveInput"
							placeholder="Zvolte město"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.municipalityForeign}
							error={getIn(errors, `municipalityForeign`)}
							touched={wasSubmitted || getIn(touched, `municipalityForeign`)}
							disabled={!values.country || isSubmitting}
						/>
					)}
				</Box>
				<Box width={[1 / 3, 1 / 4]} pr={[0, 3]} mb={3}>
					<TextInput
						id={`${attr}.zipCode`}
						name={`${attr}.zipCode`}
						label="PSČ"
						labelType="aboveInput"
						required
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.zipCode}
						error={getIn(errors, 'zipCode')}
						touched={wasSubmitted || getIn(touched, 'zipCode')}
						disabled={isSubmitting || loadingAres}
					/>
				</Box>
			</Flex>

			<Flex width="100%" flexDirection={['column-reverse', 'row']}>
				<Box width={[1, 1 / 2]} pr={[0, 4]} mb={3}>
					<RemoteSelect
						apiCall={useCountries}
						id={`${attr}.country`}
						label="Stát"
						labelType="aboveInput"
						required
						placeholder="Zvolte stát"
						onSetValue={onSetCountry}
						onSetTouched={setFieldTouched}
						value={values.country}
						error={getIn(errors, 'country')}
						touched={wasSubmitted || getIn(touched, 'country')}
						disabled={isSubmitting || loadingAres}
					/>
				</Box>
				<Box width={[1, 1 / 2]} mb={3}>
					{isCzechAddress ? (
						<RemoteSelect
							apiCall={useRegions}
							id={`${attr}.region`}
							label="Kraj"
							labelType="aboveInput"
							required
							placeholder="Zvolte kraj"
							onSetValue={onSetRegion}
							onSetTouched={setFieldTouched}
							value={values.region}
							error={getIn(errors, 'region')}
							touched={wasSubmitted || getIn(touched, 'region')}
							disabled={isSubmitting || loadingAres}
						/>
					) : (
						<TextInput
							id={`${attr}.regionForeign`}
							name={`${attr}.regionForeign`}
							label="Kraj"
							labelType="aboveInput"
							placeholder="Zvolte kraj"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.regionForeign}
							error={getIn(errors, 'regionForeign')}
							touched={wasSubmitted || getIn(touched, 'regionForeign')}
							disabled={!values.country || isSubmitting}
						/>
					)}
				</Box>
			</Flex>
		</>
	);
};
export default CompanyAddressInput;
