import React, { useCallback, useMemo } from 'react';
import { QueryResult } from 'react-query';

import { UASResult } from 'utils/UASTypes';

import SelectInput, {
	CommonSelectProps,
	MonoselectProps,
	MultiselectProps,
} from './SelectInput';

type CustomRemoteProps<T> = {
	apiCall: () => QueryResult<UASResult<T>, unknown>;
	apiCallErrorMessage?: string;
	filter?: (value: T) => boolean;
};

type RemoteSelectProps<T> = CustomRemoteProps<T> &
	Omit<CommonSelectProps<T>, 'options' | 'reloadOptions'> &
	(MonoselectProps<T> | MultiselectProps<T>);

function RemoteSelect<T>({
	nameFromOption,
	keyFromOption,
	loading = false,
	apiCall,
	apiCallErrorMessage = 'Nepodařilo se získat data. Zkuste znovu.',
	filter,
	...p
}: RemoteSelectProps<T>) {
	const response = apiCall();
	const { status } = response;

	const reloadOptions = useCallback(() => {
		return response.status === 'success' ? response.refetch : null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response.status]);

	const items = response.data?.items;
	const filteredData = useMemo(
		() => {
			if (status !== 'success') {
				return null;
			}
			if (!filter) {
				return response.data?.items ?? null;
			}
			return response.data?.items.filter(filter) ?? null;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[status, items, filter],
	);

	const nameFromOptionDefault = useCallback(
		(s: T | null) => ((s as unknown) as { name: string })?.name ?? '',
		[],
	);
	const keyFromOptionDefault = useCallback(
		(s: T | null) => ((s as unknown) as { id: string })?.id ?? '',
		[],
	);

	return p.multiselect ? (
		<SelectInput
			{...p}
			multiselect={p.multiselect}
			nameFromOption={nameFromOption ?? nameFromOptionDefault}
			keyFromOption={keyFromOption ?? keyFromOptionDefault}
			options={filteredData}
			reloadOptions={reloadOptions}
			error={
				p.error ?? (status !== 'success' ? apiCallErrorMessage : undefined)
			}
			touched={p.touched ?? (status === 'error' ? [true] : undefined)}
			loading={status === 'loading' || loading}
		/>
	) : (
		<SelectInput
			{...p}
			multiselect={p.multiselect}
			nameFromOption={nameFromOption ?? nameFromOptionDefault}
			keyFromOption={keyFromOption ?? keyFromOptionDefault}
			options={filteredData}
			reloadOptions={reloadOptions}
			error={
				p.error ?? (status !== 'success' ? apiCallErrorMessage : undefined)
			}
			touched={p.touched ?? status === 'error'}
			loading={status === 'loading' || loading}
		/>
	);
}

export default RemoteSelect;
