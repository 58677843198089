import React from 'react';

import { Box, Flex } from 'components/styled';
import Text, { Emoji } from 'components/styled/Text';
import Button from 'components/styled/Button';

import { TourContentComponent } from 'modules/tutorials/useTour';

import { setTutorialCompleted } from 'api/personApi';

export const HeaderStep: TourContentComponent = () => (
	<Box>
		<Text>Nyní jste v EDITAČNÍM ROZHRANÍ PROFILU.</Text>

		<Text>
			Budete si moct nastavit profilovou fotku a základní údaje o firmě.
			<br />
			Můžete popsat, jak to u vás chodí, koho obecně hledáte apod.
			<br />
			Najdete tu povinná i volitelná pole.
		</Text>

		<Text fontStyle="italic">
			Tip: Pamatujte na to, že mluvíte ke studentům nebo čerstvým absolventům.
		</Text>
	</Box>
);

export const BasicInfoStep: TourContentComponent = () => (
	<Box>
		<Text>Základní informace o firmě vyplníte zde.</Text>

		<Text fontStyle="italic">
			Tip: V kolonce odvětví můžete zvolit „pouze“ jednu „core“ možnost.
			<br />V rámci inzerátů ale budete moci vybrat z více možností podle
			charakteru vypsané pozice.
		</Text>
	</Box>
);

export const ContactInfoStep: TourContentComponent = () => (
	<Box>
		<Text>
			Doplňte osobu, která bude profil nejčastěji spravovat.
			<br />
			Tomuto kontaktu budou moci zájemci o spolupráci napsat nebo zavolat,
			<br />
			když budou chtít bližší informace.
		</Text>
	</Box>
);

export const LeadParagraphStep: TourContentComponent = () => (
	<Box>
		<Text>Tady vyplňte informace o Vaší firmě.</Text>
	</Box>
);

export const HowItLookStep: TourContentComponent = () => (
	<Box>
		<Text>Tady vyplňte informace o Vaší firmě.</Text>
	</Box>
);

export const AdmissionProcessStep: TourContentComponent = () => (
	<Box>
		<Text>Tady vyplňte informace o Vaší firmě.</Text>
	</Box>
);

export const OwnSectionsStep: TourContentComponent = () => (
	<Box>
		<Text>
			K dispozici máte další dva oddíly, které si můžete pojmenovat jak chcete a
			doplnit do nich co chcete.
			<br />
			Můžete s nimi pracovat po celý rok.
			<br />
			Dát tam třeba info, že se uvidíte se studenty na našem veletrhu kariéra+.
			<br />
			Dávat tam různé aktuality.
			<br />
			Je na vás, jak s profilem budete pracovat a jak se budete na VŠB-TUO
			prezentovat <Emoji label="Smile" symbol="😊" />.
			<br />
			Prostor na to je.
		</Text>
	</Box>
);

export const GalleryStep: TourContentComponent = () => (
	<Box>
		<Text>Přidejte videa nebo fotky, které Vás popíší lépe než 1000 slov…</Text>
	</Box>
);

export const PreviewProfileButtonStep: TourContentComponent = () => (
	<Box>
		<Text>
			Tímhle tlačítkem můžete profil zobrazit tak, jak ho uvidí studenti,
			absolventi nebo ostatní zaměstnavatelé.
		</Text>
	</Box>
);

export const MenuSaveStep: TourContentComponent = () => (
	<Box>
		<Text>Sekce prubezne ukladejte</Text>
	</Box>
);

export const SaveStep: TourContentComponent = () => (
	<Box>
		<Text>
			Rozpracovaný profil uložíte tímhle tlačítkem. Když bude váš profil
			dostatečně vyplněný a hotový nezapomeňte ho také zveřejnit.
		</Text>
	</Box>
);

export const FinalStep: TourContentComponent = ({ close }) => (
	<Flex flexDirection="column" alignItems="center">
		<Text textAlign="center">
			Super, teď víte, jak profil vyplnit.
			<br />
			{/* Pro úspěšné dokončení registrace a aktivaci přejděte do sekce{' '}
			<Link to="/orders" color="primary">
				Moje objednávky
			</Link>{' '}
			a dokončete vaší objednávku. */}
		</Text>
		<Button
			onClick={() => {
				setTutorialCompleted(true);
				close();
			}}
			variant="primary"
		>
			Dokončit tutoriál
		</Button>
	</Flex>
);
