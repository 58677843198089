import * as Yup from 'yup';
import { omit } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { QueryCache } from 'react-query';

import { isRichTextEmpty } from 'components/form/richtext/RichTextInput';

import { postFile } from 'api/fileApi';
import { updateStudentProfile } from 'api/studentApi';

import { sanitizeFormRichText } from 'utils/UASHelpers';
import {
	phoneValidationSchema,
	richTextMaxCharCount,
	maxCharMessage,
	longStringSchema,
} from 'utils/validation';
import { ADDRESS_INIT_VALUE } from 'utils/address';
import createProgressToast from 'utils/progressToast';

import {
	StudentUpdateDto,
	Student,
	StudentHardskill,
	DescriptionPage,
	StudentSoftskill,
} from 'typing/endpoints';
export type StudentProfileFormValues = {
	photoFile: File | null;
	medalPorfolioKC: boolean;
} & StudentUpdateDto;

export const STUDENT_PROFILE_FORM_INITIAL_VALUES: StudentProfileFormValues = {
	id: '',
	contactEmail: '',
	phone: '',
	leadParagraph: '',
	photo: (null as unknown) as StudentUpdateDto['photo'],
	photoFile: null,
	address: ADDRESS_INIT_VALUE,
	wantsRevealNotifications: true,
	wantsNewCompanyAndOfferNotifications: true,
	hasDriversLicense: false,
	businessSectors: [],
	employmentTypes: [],
	languages: [],
	studentHardskills: [],
	studentSoftskills: [],
	preferredMunicipalities: [],
	preferredRegions: [],
	descriptionPages: [],
	wantsRemoteWork: false,
	medals: [],
	medalPorfolioKC: false,
	wantsCommercialMessageNotifications: true,
};

export const convertStudentToDto = (student: Student): StudentUpdateDto => ({
	...omit(student, ['leadParagraph', 'descriptionPages']),
	...sanitizeFormRichText(student),
});

export const getStudentProfileFormInitialValues = (
	student: Student,
): StudentProfileFormValues => ({
	...STUDENT_PROFILE_FORM_INITIAL_VALUES,
	...convertStudentToDto(student),
	medalPorfolioKC:
		student.medals?.some(medal => medal === 'KC_PORTFOLIO') ?? false,
	medals: [],
});

export const studentValidationSchemaObject = {
	contactEmail: Yup.string()
		.email('Neplatná emailová adresa')
		.required('Požadované'),
	phone: phoneValidationSchema,
	leadParagraph: longStringSchema,
	studentHardskills: Yup.array<StudentHardskill>()
		.test(
			'top-5',
			'Můžete mít maximálně 5 top dovedností',
			val => !val || val.filter(sh => sh.isTopFive).length <= 5,
		)
		.required('Vyberte alespoň jednu tvrdou dovednost.'),

	studentSoftskills: Yup.array<StudentSoftskill>()
		.test('ss-reasoning', 'Požadované', function (val) {
			if ((val ?? []).filter(ss => ss.reasoning === undefined).length > 0) {
				return this.createError({
					message: 'Všechny měkké dovednosti musí obsahovat zdůvodnění.',
					path: 'studentSoftskills',
				});
			}
			if (
				(val ?? []).filter(ss => ss.reasoning.trim().length > 1000).length > 0
			) {
				return this.createError({
					message:
						'Zdůvodnění měkké dovendosti může obsahovat max. 1000 znaků.',
					path: 'studentSoftskills',
				});
			}

			return true;
		})
		.required('Vyberte alespoň jednu měkkou dovednost.'),

	descriptionPages: Yup.array(
		Yup.object({
			title: Yup.string()
				.trim()
				.max(100, maxCharMessage(100))
				.nullable()
				.required('Požadované'),
			content: richTextMaxCharCount(2000),
		}),
	),
};

export const studentProfileFormValidationSchema = Yup.object(
	studentValidationSchemaObject,
);

export const studentProfileFormSubmit = (
	afterSubmit: () => void,
	cache: QueryCache,
) => async ({
	photoFile,
	studentHardskills,
	...values
}: StudentProfileFormValues) => {
	const { infoUpdate, successUpdate, errorUpdate } = createProgressToast(
		'Ukládání změn...',
	);

	const descriptionPages =
		(values.descriptionPages.length ?? 0) < 1
			? ((null as unknown) as DescriptionPage[])
			: ((values.descriptionPages ?? []).map(dp => ({
					...dp,
					content: isRichTextEmpty(dp.content) ? '' : dp.content,
			  })) as DescriptionPage[]);

	try {
		// Format values
		const formattedValues: StudentUpdateDto = {
			...omit(values, 'medalPorfolioKC'),
			descriptionPages,
			studentHardskills: studentHardskills.map(hs =>
				!hs.id ? { ...hs, id: uuid() } : hs,
			),
			leadParagraph: isRichTextEmpty(values.leadParagraph)
				? ''
				: values.leadParagraph,
			medals: values.medalPorfolioKC ? ['KC_PORTFOLIO'] : [],
		};

		// Upload images
		if (photoFile) {
			infoUpdate(`Nahrávání souboru ${photoFile.name}...`);
			const [photo, data] = await postFile(photoFile);
			if (!photo.ok) {
				errorUpdate(
					`${photo.status}: Nepodařilo se nahrát soubor ${photoFile.name} (${photo.statusText})`,
				);
				return;
			}
			formattedValues.photo = data;
		}

		const response = await updateStudentProfile(formattedValues);

		if (response.ok) {
			cache.invalidateQueries(['student', values.id]);
			// Success
			afterSubmit();
			successUpdate('Změny uloženy');
		} else {
			errorUpdate(`${response.status}: ${response.statusText}`);
		}
	} catch (error) {
		errorUpdate('Při ukládání profilu došlo k chybě.');
	}
};
