import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { groupBy, isEqual } from 'lodash-es';

import { suggestedHardskillsCreate } from 'api/suggestedHardskillsApi';
import { useHardskills } from 'api/hardskillApi';

import { getHardskillName } from 'utils/UASHelpers';

import {
	OfferHardskill,
	StudentHardskill,
	SuggestedHardskill,
	SuggestedHardskillDto,
} from 'typing/endpoints';

const useNewSuggestedHardskills = <T extends StudentHardskill | OfferHardskill>(
	suggestedHardskills?: SuggestedHardskill[],
	sort?: (lhs: T, rhs: T) => number,
) => {
	// Hardskills for hs suggestion
	const hardskillsResponse = useHardskills();
	const hardskills = useMemo(() => hardskillsResponse.data?.items ?? [], [
		hardskillsResponse.data,
	]);

	//  Local copy of suggestions
	const [newSuggested, setNewSuggested] = useState<SuggestedHardskillDto[]>([]);

	const addSuggestedHardskill = useCallback(
		async (val: SuggestedHardskillDto) => {
			const progressToast = toast.info(
				`Odesílání navrhované dovednosti ${val.name}...`,
				{
					autoClose: false,
					closeButton: false,
					closeOnClick: false,
				},
			);

			try {
				const response = await suggestedHardskillsCreate(val);
				// Add new hs to local suggestions so it can be used immediately without refetch
				setNewSuggested(s => [...s, response]);
				toast.update(progressToast, {
					render: `Nová dovednost ${val.name} úspěšně navrhnuta. Teď ji najdete ve vašem výběru tvrdých dovedností.`,
					type: 'success',
					autoClose: 3000,
					closeButton: true,
					closeOnClick: true,
				});
			} catch (error) {
				toast.update(progressToast, {
					render: `${error.status}: ${error.statusText}`,
					type: 'error',
					autoClose: 3000,
					closeButton: true,
					closeOnClick: true,
				});
			}
		},
		[setNewSuggested],
	);

	const { true: resolved, false: unresolved } = useMemo(
		() => groupBy(suggestedHardskills ?? [], 'isResolved'),
		[suggestedHardskills],
	);

	const allSuggestedHardskills = useMemo(
		() => [...(unresolved ?? []), ...newSuggested],
		[unresolved, newSuggested],
	);

	const { true: approved, false: rejected } = useMemo(
		() => groupBy(resolved ?? [], 'isApproved'),
		[resolved],
	);

	const approvedHardskills = useMemo(() => approved ?? [], [approved]);
	const rejectedHardskills = useMemo(() => rejected ?? [], [rejected]);

	const onDelete = useCallback((val: SuggestedHardskillDto) => {
		setNewSuggested(s => s.filter(hs => !isEqual(hs, val)));
	}, []);

	const options = useMemo(() => {
		const o = [
			...(hardskillsResponse.data?.items ?? []).map(
				hardskill =>
					({
						hardskill,
					} as T),
			),
			...allSuggestedHardskills.map(
				suggestedHardskill =>
					({
						suggestedHardskill,
					} as T),
			),
		];
		return sort ? o.sort(sort) : o;
	}, [hardskillsResponse.data?.items, allSuggestedHardskills, sort]);

	const labelFromOption = useCallback(
		(hs: T | null) => getHardskillName(hs),
		[],
	);

	const keyFromOption = useCallback(
		(hs: T | null) =>
			hs ? hs?.hardskill?.id ?? hs?.suggestedHardskill?.id ?? '' : '',
		[],
	);

	return {
		hardskills,
		approvedHardskills,
		rejectedHardskills,
		allSuggestedHardskills,
		addSuggestedHardskill,
		onDelete,
		selectProps: {
			options,
			labelFromOption,
			nameFromOption: labelFromOption,
			keyFromOption,
			searchKeys: ['hardskill.name', 'suggestedHardskill.name'],
		},
	};
};

export default useNewSuggestedHardskills;
