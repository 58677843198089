import store from 'utils/Store';

import { api } from '.';

export const keepAlive = async () => {
	if (!store.get<string>(store.keys.Token)) {
		return false;
	}
	// Token is refreshed by `afterResponse` callback of `api`
	return await api()
		.get('keepalive', {
			throwHttpErrors: false,
		})
		.then(r => r?.ok);
};
