import { useQuery } from 'react-query';

import { api } from '.';

import { Person } from 'typing/endpoints';

// Person
export const getLoggedInPerson = () =>
	api().get('persons/logged-in').json<Person>();
export const useLoggedInPerson = () =>
	useQuery('persons/logged-in', getLoggedInPerson, { refetchOnMount: false });

// Settings
export const resetPassword = (email: string) =>
	api({ skipToken: true }).put('persons/password/reset', {
		searchParams: { email },
	});
export const setNewPassword = (token: string, newPassword: string) =>
	api({ skipToken: true }).put(`persons/password/new/${token}`, {
		searchParams: { newPassword },
	});

export const changePasswordLoggedInUser = (newPassword: string) =>
	api().put(`persons/password/reset/logged-in-user`, {
		searchParams: { newPassword },
	});

export const withdrawGdprConsent = () => api().put('persons/gdpr/withdraw');

export const setTutorialCompleted = (tutorialCompleted: boolean) =>
	api().put('persons/tutorial/complete', {
		searchParams: { tutorialCompleted },
	});
