import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useQueryCache } from 'react-query';

import {
	companyEventActivate,
	companyEventDeactivate,
} from 'api/companyEventsApi';

const useToggleCompanyEventActivate = (id: string) => {
	const cache = useQueryCache();

	return useCallback(
		async (activateFrom?: Date, activeTo?: Date) => {
			if (!activateFrom && !window.confirm(`Určitě chcete akci deaktivovat?`)) {
				return;
			}

			const response = await (activateFrom
				? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				  companyEventActivate(id, activateFrom ?? new Date(), activeTo!)
				: companyEventDeactivate(id));

			if (!response) {
				toast.error(
					`Akci se nepodařilo ${
						activateFrom
							? 'aktivovat. Ujistěte se, že je dostatečně vyplněna a že vám také zůstávají kredity.'
							: 'deaktivovat.'
					}`,
					{ autoClose: 5000 },
				);
				return false;
			}

			cache.invalidateQueries(['company-events', 'logged-in-company']);
			cache.invalidateQueries(['company-events', id]);
			cache.invalidateQueries(['logged-in-company']);
			toast.success(
				`Akce úspěšně ${activateFrom ? 'aktivována ' : 'deaktivována'}`,
				{ autoClose: 5000 },
			);
			return true;
		},
		[cache, id],
	);
};

export default useToggleCompanyEventActivate;
