import { FormikHelpers, FormikState } from 'formik';
import { isEqual } from 'lodash-es';
import { useMemo, useCallback } from 'react';

import { isCountryCzechRepublic } from 'utils/UASHelpers';

import { Country, Region, Municipality, vsb } from 'typing/endpoints';

const useAddressInputHandlers = (
	attr: string,
	values: FormikState<vsb.Address>['values'],
	setFieldValue: FormikHelpers<vsb.Address>['setFieldValue'],
) => {
	const isCzechAddress = useMemo(() => isCountryCzechRepublic(values.country), [
		values.country,
	]);

	const onSetCountry = useCallback(
		(field: string, value: Country | null) => {
			if (isCountryCzechRepublic(value)) {
				setFieldValue(`${attr}.regionForeign`, '');
				setFieldValue(`${attr}.municipalityForeign`, '');
			} else {
				setFieldValue(`${attr}.region`, null);
				setFieldValue(`${attr}.municipality`, null);
			}
			setFieldValue(field, value);
		},
		[attr, setFieldValue],
	);

	const onSetRegion = useCallback(
		(field: string, value: Region | null) => {
			if (!isEqual(value, values.region)) {
				setFieldValue(`${attr}.municipality`, null);
			}
			setFieldValue(field, value);
		},
		[attr, setFieldValue, values.region],
	);

	const onSetMunicipality = useCallback(
		(field: string, value: Municipality | null) => {
			if (value && !isEqual(value.region, values.region)) {
				setFieldValue(`${attr}.region`, value.region);
			}
			setFieldValue(field, value);
		},
		[attr, setFieldValue, values.region],
	);

	const municipalitiesFilter = useCallback(
		(m: Municipality) => !values.region || m.region.id === values.region.id,
		[values.region],
	);

	return {
		isCzechAddress,
		onSetCountry,
		onSetRegion,
		onSetMunicipality,
		municipalitiesFilter,
	};
};

export default useAddressInputHandlers;
