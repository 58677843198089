/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';
import { MdSchedule } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useQueryCache } from 'react-query';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { resetButtonStyle } from 'components/styled/Button';

import {
	BasketIcon,
	CheckmarkIcon,
	NotificationsEmptyIcon,
	OfferIcon,
	PackageIcon,
	RevealsIcon,
	SettingsIcon,
} from 'assets';
import { getDateString, getTimeString } from 'utils';
import { Theme } from 'theme';

import { markNotificationRead } from 'api/notificationsApi';

import { NotificationColors, NotificationTypeVariant } from 'utils/enumMaps';

import { MainNotification, Person } from 'typing/endpoints';

const notificationIcon = (type: NotificationTypeVariant, photoId?: string) => {
	switch (type) {
		case 'Nová reakce na inzerát':
			return photoId ? (
				<Box
					as={p => <img src={`/api/files/${photoId}`} alt="Avatar" {...p} />}
					size={20}
				/>
			) : (
				<CheckmarkIcon size={16} />
			);
		case 'Objednávka zamítnuta':
		case 'Objednávka přijata':
		case 'Nezaplacená objednávka':
			return <BasketIcon size={20} />;
		case 'Docházející odkrytí studentů':
			return <RevealsIcon size={20} />;
		case 'Inzerát končí za 7 dní':
		case 'Inzerát končí zítra':
		case 'Inzerát byl deaktivován':
		case 'Nový inzerát sledované firmy':
		case 'Odpověď na inzerát přečtena':
			return <OfferIcon size={20} />;
		case 'Licence končí za jeden měsíc':
		case 'Licence končí za dva týdny':
		case 'Licence končí za jeden týden':
		case 'Licence firmy končí za dva týdny':
		case 'Nová objednávka ke schválení':
			return <PackageIcon size={20} />;
		case 'Nový návrh tvrdé dovednosti':
		case 'Schválení návrhu tvrdé dovednosti':
		case 'Zamítnutí návrhu tvrdé dovednosti':
		case 'Odebrání tvrdé dovednosti z vašeho inzerátu':
		case 'Odebrání tvrdé dovednosti z vašeho profilu':
			return <SettingsIcon color="white" size={16} />;
		case 'Firma byla schválena':
		case 'Registrace firmy do k+ portálu':
		case 'Registrace nové firmy do k+ portálu':
		case 'Registrace absolventa do k+ portálu':
			return <CheckmarkIcon size={16} />;
		case 'Notifikace s vlastním obsahem':
		default:
			return <NotificationsEmptyIcon size={20} />;
	}
};

const notificationRedirectLegacy = (
	type: NotificationTypeVariant,
	personId?: string,
) => {
	switch (type) {
		case 'Nová reakce na inzerát':
			return personId ? `/student/${personId}` : '/offers';
		case 'Objednávka zamítnuta':
		case 'Objednávka přijata':
		case 'Nezaplacená objednávka':
		case 'Licence končí za jeden měsíc':
		case 'Licence končí za dva týdny':
		case 'Licence končí za jeden týden':
		case 'Licence firmy končí za dva týdny':
			return '/orders';
		case 'Docházející odkrytí studentů':
			return '/orders/new';
		case 'Inzerát končí za 7 dní':
		case 'Inzerát končí zítra':
		case 'Inzerát byl deaktivován':
			return '/offers';
		case 'Nová objednávka ke schválení':
			return '/dashboard/orders';
		case 'Nový návrh tvrdé dovednosti':
			return '/dashboard/hardskills';
		case 'Nový inzerát sledované firmy':
			return '/dashboard/companies';
		case 'Odpověď na inzerát přečtena':
			return '/dashboard/reactions';
		case 'Registrace firmy do k+ portálu':
		case 'Registrace nové firmy do k+ portálu':
			return '/search/companies';
		case 'Nový inzerát':
			return '/search/offers';
		case 'k+ portál | Vygenerovány nové statistiky':
			return '/statistics';
		default:
			return '/dashboard';
	}
};

type NotificationRedirectInfo = Pick<
	MainNotification,
	'mainNotificationSubject' | 'recipientType' | 'notificationTemplateType'
> & { type: NotificationTypeVariant; personId?: string };

const notificationRedirect = ({
	mainNotificationSubject,
	recipientType,
	notificationTemplateType,
	type,
	personId,
}: NotificationRedirectInfo) => {
	if (!mainNotificationSubject || !recipientType || !notificationTemplateType) {
		//this is redirect for old notifications without new specifiation atributes
		return notificationRedirectLegacy(type, personId);
	}
	const targetId = mainNotificationSubject?.subjectEntityId ?? '';
	switch (mainNotificationSubject.subjectEntityType) {
		case 'OFFER': {
			const isCandidate =
				notificationTemplateType === 'NEW_RESPONSE_TO_OFFER'
					? 'candidates/'
					: '';
			return `/offer/${isCandidate}${targetId}`;
		}
		case 'COMPANY': {
			return `/company/${targetId}`;
		}
		default: {
			if (notificationTemplateType === 'GENERATED_STATISTICS') {
				return `/statistics`;
			}
		}
	}

	return notificationRedirectLegacy(type, personId);
};

// TODO: Student avatar preview and direct link
type Props = MainNotification & {
	variant: 'dashboard' | 'header';
	person?: Pick<Person, 'id' | 'photo'>;
	onClick?: () => void;
};

const NotificationItem: React.FC<Props> = ({
	variant,
	id,
	created,
	title,
	description,
	read,
	person,
	onClick,
	mainNotificationSubject,
	notificationTemplateType,
	recipientType,
}) => {
	const { push } = useHistory();
	const cache = useQueryCache();

	return (
		<Flex
			as="button"
			alignItems="center"
			color={!read ? 'text' : 'textLight'}
			//maxWidth="100%"
			py={variant === 'dashboard' ? 2 : 0}
			onClick={async () => {
				await markNotificationRead(id);
				cache.invalidateQueries('notifications');
				push(
					notificationRedirect({
						mainNotificationSubject,
						notificationTemplateType,
						recipientType,
						type: title as NotificationTypeVariant,
						personId: person?.id,
					}),
				);
				onClick?.();
			}}
			css={css`
				${resetButtonStyle}
				text-align: left;
			`}
		>
			{/* Icon */}
			<Flex
				mr={2}
				flexShrink={0}
				size={35}
				justifyContent="center"
				color="white"
				alignItems="center"
				bg={NotificationColors[title as NotificationTypeVariant] ?? 'secondary'}
				css={css`
					border-radius: 50%;
				`}
			>
				{notificationIcon(title as NotificationTypeVariant, person?.photo.id)}
			</Flex>

			{/* Content */}
			<Flex
				flexDirection="column"
				css={
					variant === 'dashboard' &&
					css`
						&:hover {
							text-decoration: underline;
						}
					`
				}
			>
				<Text
					fontWeight="bold"
					m={0}
					css={css`
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					`}
				>
					{title}
				</Text>
				<Box
					dangerouslySetInnerHTML={{ __html: description }}
					css={css`
						& p {
							margin: 0;
							white-space: normal;
						}
						& p:nth-of-type(1) {
							display: none;
						}
						& p:nth-of-type(3) {
							display: none;
						}
					`}
				/>
			</Flex>

			{/* Time */}
			{variant === 'dashboard' && (
				<Flex
					justifyContent="flex-end"
					alignSelf="flex-start"
					flexGrow={1}
					flexShrink={0}
					ml={2}
					color="textLight"
					css={(theme: Theme) => css`
						@media (max-width: ${theme.breakpoints[0]}) {
							display: none;
						}
					`}
				>
					<Flex alignItems="center">
						<MdSchedule />
						<Text ml={2}>
							{getDateString(created)} o {getTimeString(created)}
						</Text>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};
export default NotificationItem;
