import React from 'react';
import { matchPath } from 'react-router-dom';

import { Wrapper } from 'components/styled/Wrapper';
import { LazyRoute, RouteItem, RouterItem } from 'components/routing/LazyRoute';

import Lazy from 'auth/lazyComps';

import { ApiUser, isAdminUser, isRecruiterUser, isStudentUser } from './token';

const COMMON_ROUTES: RouteItem[] = [
	{
		path: '/popup-redirect',
		render: LazyRoute(Lazy.PopupRedirect),
		type: 'route',
	},
];

const PUBLIC_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/',
		render: LazyRoute(Lazy.Homepage),
	},
	{
		type: 'route',
		path: '/conditions',
		render: LazyRoute(Lazy.Conditions),
	},
	{
		type: 'route',
		path: '/how-it-works/student',
		render: LazyRoute(Lazy.HowItWorksStudent),
	},
	{
		type: 'route',
		path: '/how-it-works/absolvent',
		render: LazyRoute(Lazy.HowItWorksAbsolvent),
	},
	{
		type: 'route',
		path: '/how-it-works/company',
		render: LazyRoute(Lazy.HowItWorksCompany),
	},
	{
		type: 'route',
		path: '/external-login',
		render: LazyRoute(Lazy.ExternalLogin),
	},
	{
		type: 'route',
		path: '/company/:id',
		render: LazyRoute(Lazy.PublicCompanyProfile),
	},
	{
		type: 'route',
		path: '/companies',
		render: LazyRoute(Lazy.PublicCompaniesList),
	},
	{
		type: 'route',
		path: '/offers',
		render: LazyRoute(Lazy.PublicOffersList),
	},
	{
		type: 'route',
		path: '/embed/offers',
		render: LazyRoute(Lazy.EmbedOffersCarousel),
	},
	{
		type: 'route',
		path: '/events',
		render: LazyRoute(Lazy.PublicEvents),
	},
	...COMMON_ROUTES,
];

const LOGIN_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/login',
		render: LazyRoute(Lazy.LoginRedirect),
	},
	{
		type: 'route',
		path: '/login/company',
		render: LazyRoute(Lazy.Login, { variant: 'other' }),
	},
	{
		type: 'route',
		path: '/forgottenPassword/company',
		render: LazyRoute(Lazy.ForgottenPassword, { variant: 'other' }),
	},
	{
		type: 'route',
		path: '/registration/company',
		render: LazyRoute(Lazy.CompanyRegistration),
	},
	{
		type: 'route',
		path: '/registration/company/done',
		render: LazyRoute(Lazy.CompanyRegistrationDone),
	},
	{
		type: 'route',
		path: '/login/absolvent',
		render: LazyRoute(Lazy.Login, { variant: 'absolvent' }),
	},
	{
		type: 'route',
		path: '/forgottenPassword/absolvent',
		render: LazyRoute(Lazy.ForgottenPassword, { variant: 'absolvent' }),
	},
	{
		type: 'route',
		path: '/registration/absolvent',
		render: LazyRoute(Lazy.GraduateRegistration),
	},
	{
		type: 'route',
		path: '/registration/absolvent/done',
		render: LazyRoute(Lazy.GraduateRegistrationDone),
	},
	{
		type: 'route',
		path: '/resetPassword/:token',
		render: LazyRoute(Lazy.ResetPassword),
	},
	{
		type: 'route',
		path: '/login/reset-attempts/:token',
		render: LazyRoute(Lazy.LoginResetAttempts),
	},
];

const COMPANY_ROUTES: RouterItem[] = [
	{
		type: 'route',
		path: '/dashboard',
		render: LazyRoute(Lazy.CompanyDashboard),
	},
	{
		type: 'route',
		path: '/offers',
		render: LazyRoute(Lazy.CompanyOwnOffers),
	},
	{
		type: 'route',
		path: '/notifications',
		render: LazyRoute(Lazy.CompanyNotifications),
	},
	{
		type: 'route',
		path: '/offer/new',
		render: LazyRoute(Lazy.OfferNew),
	},
	{
		type: 'route',
		path: '/offer/edit/:id',
		render: LazyRoute(Lazy.OfferEdit),
	},
	{
		type: 'route',
		path: '/offer/candidates/:id',
		render: LazyRoute(Lazy.OfferCandidates),
	},
	//TODO:
	{
		type: 'route',
		path: '/company-events',
		render: LazyRoute(Lazy.CompanyOwnEvents),
	},
	{
		type: 'route',
		path: '/company-event/new',
		render: LazyRoute(Lazy.CompanyEventsNew),
	},
	{
		type: 'route',
		path: '/company-event/:id',
		render: LazyRoute(Lazy.CompanyEventDetail),
	},
	{
		type: 'route',
		path: '/company-event/edit/:id',
		render: LazyRoute(Lazy.CompanyEventEdit),
	},
	{
		type: 'route',
		path: '/orders',
		render: LazyRoute(Lazy.CompanyOrders),
	},
	{
		type: 'route',
		path: '/orders/new',
		render: LazyRoute(Lazy.CompanyOrdersNew),
	},
	{
		type: 'route',
		path: '/settings',
		render: LazyRoute(Lazy.CompanySettings),
	},
	{
		// Profile edit
		type: 'route',
		path: '/company/edit',
		render: LazyRoute(Lazy.CompanyProfileEdit),
	},
	{
		// Own/Other company profiles
		type: 'route',
		path: '/company/:id?',
		render: LazyRoute(Lazy.CompanyProfile),
	},
	{
		type: 'route',
		path: '/offer/:id',
		render: LazyRoute(Lazy.Offer),
	},
	{
		type: 'route',
		path: '/student/:id',
		render: LazyRoute(Lazy.Student),
	},
	{
		type: 'route',
		path: '/search/companies',
		render: LazyRoute(Lazy.CompanySearchCompanies),
	},
	{
		type: 'route',
		path: '/search/students',
		render: LazyRoute(Lazy.CompanySearchAlgoStudents),
	},
	{
		type: 'route',
		path: '/search/offers',
		render: LazyRoute(Lazy.CompanySearchOffers),
	},
	{
		type: 'route',
		path: '/search/company-events',
		render: LazyRoute(Lazy.CompanySearchCompanyEvents),
	},
	...COMMON_ROUTES,
];

export const STUDENT_GDPR_ROUTE_NAME = '/student/gdpr';

const STUDENT_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/',
		to: '/dashboard',
	},
	{
		type: 'route',
		path: '/dashboard',
		render: LazyRoute(Lazy.StudentDashboard),
	},
	{
		type: 'route',
		path: STUDENT_GDPR_ROUTE_NAME,
		render: LazyRoute(Lazy.ConsentGDPR),
	},
	{
		type: 'route',
		path: '/dashboard/notifications',
		render: LazyRoute(Lazy.StudentNotifications),
	},
	{
		type: 'route',
		path: '/dashboard',
		// eslint-disable-next-line react/display-name
		render: () => <Wrapper>Student dashboard</Wrapper>,
	},

	{
		type: 'route',
		path: '/company/:id',
		render: LazyRoute(Lazy.CompanyProfile),
	},
	{
		type: 'route',
		path: '/offer/:id',
		render: LazyRoute(Lazy.Offer),
	},
	{
		type: 'route',

		path: '/settings',
		render: LazyRoute(Lazy.StudentSettings),
	},
	{
		//TODO: TEMP ROUTE
		// Profile edit
		type: 'route',
		path: '/student/edit',
		render: LazyRoute(Lazy.StudentProfileEditREDESIGN),
	},
	{
		//TODO: TEMP ROUTE
		// Profile edit
		type: 'route',
		path: '/student',
		render: LazyRoute(Lazy.StudentProfileEditREDESIGN),
	},
	{
		// Own/Other student profiles
		type: 'route',
		path: '/student/view-as-company',
		render: LazyRoute(Lazy.Student),
	},
	{
		// Own/Other student profiles
		type: 'route',
		path: '/student/:id',
		render: LazyRoute(Lazy.Student),
	},
	{
		type: 'route',
		path: '/search/companies',
		render: LazyRoute(Lazy.StudentSearchCompanies),
	},
	{
		type: 'route',
		path: '/search/offers',
		render: LazyRoute(Lazy.OffersRouteByUser),
	},
	{
		type: 'route',
		path: '/search/students',
		render: LazyRoute(Lazy.SearchStudents),
	},
	{
		type: 'route',
		path: '/introduction-form',
		render: LazyRoute(Lazy.StudentIntroductionForm),
	},
	{
		type: 'route',
		path: '/my-reactions',
		render: LazyRoute(Lazy.StudentMyReactions),
	},

	{
		type: 'route',
		path: '/my-offers',
		render: LazyRoute(Lazy.StudentMyOffers),
	},
	{
		type: 'route',
		path: '/my-companies',
		render: LazyRoute(Lazy.StudentMyCompanies),
	},
	{
		type: 'route',
		path: '/students-to-students',
		render: LazyRoute(Lazy.StudentsToStudents),
	},
	{
		type: 'route',
		path: '/search/company-events',
		render: LazyRoute(Lazy.SearchCompanyEvents),
	},
	{
		type: 'route',
		path: '/company-event/:id',
		render: LazyRoute(Lazy.CompanyEventDetail),
	},
	...COMMON_ROUTES,
];

const ADMIN_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/',
		to: '/dashboard',
	},
	{
		type: 'route',
		path: '/dashboard/:tab?',
		render: LazyRoute(Lazy.AdminDashboard),
	},
	{
		type: 'route',
		path: '/orders',
		render: LazyRoute(Lazy.AdminOrders),
	},
	{
		type: 'route',
		path: '/orders/new/:companyId',
		render: LazyRoute(Lazy.CompanyOrdersNew),
	},
	{
		type: 'route',
		path: '/search/students',
		render: LazyRoute(Lazy.SearchStudents),
	},
	{
		type: 'route',
		path: '/statistics/:type?/:id?',
		render: LazyRoute(Lazy.AdminStatistics),
	},
	{
		type: 'route',
		path: '/products',
		render: LazyRoute(Lazy.AdminProducts),
	},
	{
		type: 'route',
		path: '/search/company-events',
		render: LazyRoute(Lazy.AdminSearchCompanyEvents),
	},

	{
		type: 'route',
		path: '/search/offers',
		render: LazyRoute(Lazy.AdminSearchOffers),
	},
	{
		type: 'route',
		path: '/search/companies',
		render: LazyRoute(Lazy.AdminSearchCompanies),
	},
	{
		type: 'route',
		path: '/company-event/:id',
		render: LazyRoute(Lazy.CompanyEventDetail),
	},
	{
		type: 'route',
		path: '/settings/:tab?/:id?',
		render: LazyRoute(Lazy.AdminSettings),
	},

	...COMMON_ROUTES,
];

// Super Admin
export const SUPER_ADMIN_ROUTES: RouterItem[] = [
	{
		type: 'redirect',
		path: '/',
		to: '/dashboard',
	},
	{
		type: 'route',
		path: '/dashboard/:tab?',
		render: LazyRoute(Lazy.AdminDashboard),
	},
	{
		// Company profile edit
		type: 'route',
		path: '/company/edit/:id',
		render: LazyRoute(Lazy.CompanyProfileEdit),
	},
	{
		// Student profile edit
		type: 'route',
		path: '/student/edit/:id',
		render: LazyRoute(Lazy.StudentProfileEdit),
	},
	...COMMON_ROUTES,
];

export const isAuthPath = (pathname: string) =>
	[
		...SUPER_ADMIN_ROUTES,
		...ADMIN_ROUTES,
		...COMPANY_ROUTES,
		...STUDENT_ROUTES,
	].some(r => (r.type === 'redirect' ? false : matchPath(pathname, r)));

// Get routes based on user authentication
export const getUserRoutes = (user?: ApiUser) => {
	if (isStudentUser(user)) {
		// Student routes
		return [...STUDENT_ROUTES, ...PUBLIC_ROUTES];
	}

	if (isRecruiterUser(user)) {
		// Company routes
		return [...COMPANY_ROUTES, ...PUBLIC_ROUTES];
	}

	if (isAdminUser(user)) {
		// Super Admin routes
		return [
			...SUPER_ADMIN_ROUTES,
			...ADMIN_ROUTES,
			...COMPANY_ROUTES,
			...STUDENT_ROUTES,
			...PUBLIC_ROUTES,
		];
	}

	return [...PUBLIC_ROUTES, ...LOGIN_ROUTES];
};
