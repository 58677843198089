import { useQuery } from 'react-query';

import { UASResult } from 'utils/UASTypes';

import { api } from '.';

import { Softskill } from 'typing/endpoints';

export const useSoftskills = () =>
	useQuery(
		'softskills',
		() =>
			api()
				.get('softskills', { searchParams: { pageSize: -1 } })
				.json<UASResult<Softskill>>(),
		{ staleTime: 3600000 },
	);

export const updateSoftskill = (ss: Softskill) =>
	api().put(`softskills/${ss.id}`, { json: ss });

export const deleteSoftskill = (ss: Softskill) =>
	api().delete(`softskills/${ss.id}`);
