/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { ComponentProps, FC } from 'react';

import { Flex, FlexProps } from 'components/styled';
import { resetButtonStyle } from 'components/styled/Button';

import { Theme, InvertFocusStyle } from 'theme';

type Props = FlexProps & ComponentProps<'button'>;

const RTButton: FC<Props> = ({ children, ...props }) => {
	return (
		<Flex
			as="button"
			{...{ type: 'button' }}
			ml={1}
			px={[1, 1, 2]}
			height={31}
			my={2}
			minWidth={20}
			justifyContent="center"
			alignItems="center"
			flexShrink={0}
			{...((props as unknown) as FlexProps)}
			css={(theme: Theme) => css`
				font-size: ${theme.fontSizes.md}px;
				color: white;
				${resetButtonStyle}
				&:focus {
					${InvertFocusStyle}
				}

				&.ql-active {
					color: ${theme.colors.secondary};
				}
			`}
		>
			{children}
		</Flex>
	);
};
export default RTButton;
