import { Quill } from 'react-quill';

import { MakeTuple } from 'utils';

const Video = Quill.import('formats/video');

const ATTRIBUTES = MakeTuple('height', 'width', 'class', 'style', 'src');
type AttrType = typeof ATTRIBUTES[number];
type Props = Record<AttrType, string>;

class CustomVideo extends Video {
	static create(props: Props | string) {
		const node = super.create(
			typeof props === 'string' ? props : props.src,
		) as Element;

		if (typeof props !== 'string') {
			ATTRIBUTES.forEach(attr => {
				props[attr] && node.setAttribute(attr, props[attr]);
			});
		}

		// Set non-format related attributes with static values
		node.setAttribute('frameborder', '0');
		node.setAttribute('allowfullscreen', 'true');

		return node;
	}

	static formats(domNode: Element) {
		return ATTRIBUTES.reduce((prev, attribute) => {
			const copy = { ...prev };
			if (domNode.hasAttribute(attribute)) {
				copy[attribute] = domNode.getAttribute(attribute) as string;
			}
			return copy;
		}, {} as Props);
	}

	static value(node: Element) {
		return ATTRIBUTES.reduce((prev, attribute) => {
			const copy = { ...prev };
			if (node.hasAttribute(attribute)) {
				copy[attribute] = node.getAttribute(attribute) as string;
			}
			return copy;
		}, {} as Props);
	}

	format(name: AttrType, value?: string) {
		if (ATTRIBUTES.indexOf(name) > -1) {
			if (value) {
				(this.domNode as Element).setAttribute(name, value);
			} else {
				(this.domNode as Element).removeAttribute(name);
			}
		} else {
			super.format(name, value);
		}
	}
}

export default CustomVideo;
