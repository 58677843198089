/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { ReactNode } from 'react';
import { FieldMetaProps } from 'formik/dist/types';
import TextareaAutosize, {
	TextareaAutosizeProps,
} from 'react-textarea-autosize';

import { Box, Flex } from 'components/styled';
import LoaderSpin from 'components/loaders/LoaderSpin';

import { Theme } from 'theme';

import ErrorFeedback from '../error/ErrorFeedback';

import { InputWrapper, Label } from './TextInput';

export type TextAreaInputProps = {
	label?: string | ReactNode;
	labelMinWidth?: string;
	value?: string;
	error?: FieldMetaProps<string>['error'];
	touched?: boolean;
	loading?: boolean;
	minRows?: number;
} & Omit<TextareaAutosizeProps, 'ref'>;

const TextAreaInput: React.FC<TextAreaInputProps> = ({
	id,
	label,
	required,
	error,
	touched,
	loading,
	labelMinWidth = '110px',
	...inputProps
}) => {
	return (
		<Box width={1}>
			{label && (
				<Label htmlFor={id} pr={2} minWidth={labelMinWidth} required={required}>
					{label}{' '}
				</Label>
			)}
			<Flex flexGrow={1} position="relative">
				<InputWrapper
					width={1}
					alignItems="center"
					hasError={!!error && touched}
				>
					<TextareaAutosize
						id={id}
						minRows={3}
						{...inputProps}
						css={(theme: Theme) => css`
							flex-basis: 100%;
							border: none;
							background: none;
							padding: ${theme.space[1]}px;

							&:focus {
								box-shadow: none;
							}
						`}
					/>

					{loading && (
						<Box minWidth="auto" ml={1} position="absolute" top={1} right={1}>
							<LoaderSpin size={23} />
						</Box>
					)}
				</InputWrapper>
			</Flex>

			{error && touched && (
				<ErrorFeedback>
					{Array.isArray(error) ? error.join('. ') : error}
				</ErrorFeedback>
			)}
		</Box>
	);
};
export default TextAreaInput;
