/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC } from 'react';
import { QueryResult } from 'react-query';

import { ResponsiveWrapper, Wrapper } from 'components/styled/Wrapper';
import Text from 'components/styled/Text';
import Button from 'components/styled/Button';
import { Box } from 'components/styled';

import fileSearchingImage from 'assets/file-searching.gif';
type Props = Pick<QueryResult<unknown, unknown>, 'error' | 'refetch'>;

const ErrorScreen: FC<Props> = ({ error, refetch }) => (
	<Wrapper bg="white" position="relative">
		<ResponsiveWrapper alignItems="flex-start" mt={4}>
			<Box
				top={0}
				left={0}
				width="100%"
				height="100%"
				opacity="0.2"
				zIndex={0}
				css={theme => css`
					background-image: url(${fileSearchingImage});
					background-repeat: no-repeat;
					background-size: contain;
					position: absolute;
					background-size: 43%;
					background-position: center 24%;
					@media screen and (max-width: ${theme.breakpoints[1]}) {
						background-size: 80%;
						background-position: center right;
					}
					@media screen and (max-width: ${theme.breakpoints[0]}) {
						background-size: 50%;
						background-position: center right;
					}
					@media screen and (max-width: 500px) {
						background-size: 80%;
						background-position: center right;
						opacity: 0.2;
					}
				`}
			/>
			<Box zIndex={1}>
				<Text
					color="text"
					fontSize="xl"
					fontWeight="bold"
					mt={3}
					mb={4}
					as="h2"
				>
					Nastala chyba během získávání dat!
					{error ? ` (${(error as Error)?.message ?? 'unknown error'})` : ''}
				</Text>

				<Text>Prosím zkuste operaci znovu.</Text>

				<Button mt={3} onClick={() => refetch()}>
					Zkusit znovu
				</Button>
			</Box>
		</ResponsiveWrapper>
	</Wrapper>
);

export default ErrorScreen;
