import { HEADER_WRAPPER_ID } from 'components/header';

export const TutorialCompanyProfileIDs = {
	INTRO: {
		PAGE_HEADER: HEADER_WRAPPER_ID,
	},
	DETAIL: {
		COMPANY_HEADER_ID: 'company-profile-header',
		FLOATING_MENU_BUTTON_ID: 'company-profile-action-button',
		EDIT_BUTTON_ID: 'company-profile-edit-button',
	},
	EDIT: {
		COMPANY_HEADER_ID: 'company-edit-header',
		COMPANY_BASIC_INFO_ID: 'company-edit-basic-info',
		COMPANY_CONTACT_ID: 'company-edit-contact-info',
		COMPANY_LEAD_PARAGRAPH_ID: 'company-edit-lead-paragraph',
		COMPANY_DESCRIPTION_PAGES: {
			HOW_IT_LOOK_ID: 'company-edit-desc-page-how-it-looks',
			ADMISSION_PROCESS_ID: 'company-edit-desc-page-adminssion-process',
		},
		COMPANY_OWN_SECTIONS_ID: 'company-edit-own-sections',
		COMPANY_GALLERY_ID: 'company-edit-gallery',
		FLOATING_MENU_BUTTON_ID: 'company-edit-action-button',
		SAVE_BUTTON_ID: 'company-edit-save-button',
		PREVIEW_PROFILE: 'tutorial-company-profile-preview',
	},
};
