import { space, SpaceProps } from 'styled-system';

import { Box } from 'components/styled';

import styled from 'theme/styled';

const Divider = styled(Box)<
	{
		borderColor?: string;
		size?: number;
	} & SpaceProps
>`
	width: auto;
	align-self: stretch;

	border: ${p => p.size ?? 1}px solid
		${p => {
			if (p.borderColor) {
				if (p.borderColor in p.theme.colors) {
					const borderClr = p.borderColor as keyof typeof p.theme.colors;
					return p.theme.colors[borderClr];
				} else {
					return p.borderColor;
				}
			}
			return p.theme.colors.primaryLight;
		}};
	border-top: 0;
	border-left: 0;

	${space}
`;

export default Divider;
