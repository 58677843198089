import { useQuery } from 'react-query';

import { UASResult, UASParams } from 'utils/UASTypes';

import { api } from '.';

import { MainNotification } from 'typing/endpoints';

const getNotifications = (json: UASParams = { pageSize: -1 }) =>
	api()
		.post('main-notifications/received', { json })
		.json<UASResult<MainNotification>>();

export const useNotificationsAll = () =>
	useQuery('main-notifications', () => getNotifications());

export const useNotificationsNewest = () =>
	useQuery(
		['main-notifications', 'newest'],
		() =>
			getNotifications({
				sorting: [
					{ sort: 'read', order: 'ASC' },
					{ sort: 'created', order: 'DESC' },
				],
				pageSize: 3,
			}),
		{ staleTime: 30000 },
	);

export const markNotificationRead = (id: string) =>
	api().post(`main-notifications/${id}/read`);

export const markNotificationUnread = (id: string) =>
	api().post(`main-notifications/${id}/unread`);

export const markAllNotificationRead = () =>
	api().post(`main-notifications/read/all`);

export const useNotificationUnreadCount = () =>
	useQuery(
		['main-notifications', 'count'],
		() => api().get('main-notifications/received/count').json<number>(),
		{ staleTime: 30000 },
	);
