import { v4 as uuid } from 'uuid';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

import { responseWithData } from 'utils';
import { useUserToken } from 'auth';

import { isAdminUser } from 'auth/token';

import useInfiniteUASQuery from 'hooks/useInfiniteUASQuery';

import { UASParams, UASResult } from 'utils/UASTypes';

import { api } from '.';

import { CompanyEvent, CompanyEventSaveDto } from 'typing/endpoints';

// Offer

export const useCompanyEvent = (id: string) =>
	useQuery(['company-events', id], (_key: string, id: string) =>
		api().get(`company-events/${id}`).json<CompanyEvent>(),
	);

export const useCompanyEventDto = (id: string) =>
	useQuery(['company-events/dto', id], (_key: string, id: string) =>
		api().get(`company-events/${id}/dto`).json<CompanyEvent>(),
	);

// Offers

const getEvents = (json: UASParams) =>
	api()
		.post(`company-events/parametrized`, { json })
		.json<UASResult<CompanyEvent>>();

export const getEventsDto = (json: UASParams) =>
	api().post('company-events/dto', { json }).json<UASResult<CompanyEvent>>();

export const useCompanyEvents = (json: UASParams) => {
	const token = useUserToken();
	const useDto = !isAdminUser(token);
	return useInfiniteUASQuery<CompanyEvent | CompanyEvent, [UASParams, boolean]>(
		['company-events', json, useDto],
		(_key, params, useDto, page = 0) =>
			useDto
				? getEventsDto({ ...params, page })
				: getEvents({ ...params, page }),
	);
};

export const useCompanyEventsForLoggedInCompany = () =>
	useInfiniteUASQuery<CompanyEvent>(
		['company-events', 'logged-in-company'],
		(_key, _json, page = 0) =>
			api()
				.post('company-events/logged-in-company', {
					json: { page },
				})
				.json<UASResult<CompanyEvent>>(),
		{ staleTime: 36000000 },
	);

export const useAllCompanyEventsForLoggedInCompany = () =>
	useQuery(
		['company-events', 'logged-in-company', 'all'],
		() =>
			api()
				.post('company-events/logged-in-company', {
					json: { pageSize: -1 },
				})
				.json<UASResult<CompanyEvent>>(),
		{ refetchOnWindowFocus: true, refetchOnMount: true },
	);

export const useCompanyEventsPaginated = (json: UASParams) => {
	const token = useUserToken();
	const useDto = !isAdminUser(token);
	return useInfiniteUASQuery<CompanyEvent | CompanyEvent, [UASParams, boolean]>(
		['company-events', json, useDto],
		(_key, params, useDto) =>
			useDto ? getEventsDto({ ...params }) : getEvents({ ...params }),
	);
};

export const useCompanyEventsPublic = () =>
	useQuery('company-events/active-for-homepage/', () =>
		api({ skipToken: true })
			.get('company-events/active-for-homepage/')
			.json<CompanyEvent[]>(),
	);

// Edit
export const saveCompanyEvent = (
	companyEvent: Partial<Omit<CompanyEventSaveDto, 'id'>>,
	id: string = uuid(),
) =>
	responseWithData<CompanyEvent>(
		api().put(`company-events/${id}/save`, {
			json: { ...companyEvent, id },
		}),
	);

// Activation

export const companyEventActivate = async (
	id: string,
	activateFrom: Date,
	activeTo: Date,
) =>
	(
		await api().put(
			`company-events/${id}/activate?activateFrom=${format(
				activateFrom,
				'yyyy-MM-dd',
			)}&activeTo=${format(activeTo, 'yyyy-MM-dd')}`,
			{ throwHttpErrors: false },
		)
	)?.ok;

export const companyEventDeactivate = async (id: string) =>
	(
		await api().put(`company-events/${id}/deactivate`, {
			throwHttpErrors: false,
		})
	).ok;

// Other

export const companyEventClick = async (id: string) =>
	await api().post(`company-events/${id}/click_url`, {
		throwHttpErrors: false,
	});
