import { useQuery } from 'react-query';

import { api } from 'api';
import { useUserToken } from 'auth';

import { AssignedPopUpWindow, PopUpWindowDto } from 'typing/endpoints';

const useLoggedInPopup = () => {
	const token = useUserToken();
	return useQuery(
		['logged-in', 'assigned-popup', token],
		() =>
			api().get('assigned-pop-up-windows/logged-in').json<PopUpWindowDto[]>(),
		{ enabled: token, staleTime: 60000, retry: 1 },
	);
};

export const usePopup = (id?: string) =>
	useQuery(
		['popup', id],
		() =>
			api().get(`assigned-pop-up-windows/${id}`).json<AssignedPopUpWindow>(),
		{ enabled: id },
	);

export default useLoggedInPopup;
