import { useQuery } from 'react-query';

import { UASResult } from 'utils/UASTypes';

import { api } from '.';

import { Municipality } from 'typing/endpoints';

export const useMunicipalities = () =>
	useQuery(
		'municipalities',
		() =>
			api({ skipToken: true })
				.get('municipalities', { searchParams: { pageSize: -1 } })
				.json<UASResult<Municipality>>(),
		{ staleTime: 3600000 },
	);
