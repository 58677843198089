/** @jsxImportSource @emotion/core */
import { getIn, useFormik } from 'formik';
import { omit } from 'lodash-es';
import React, { FC } from 'react';
import { useQueryCache } from 'react-query';
import { v4 as uuid } from 'uuid';

import { Box, Flex } from 'components/styled';
import SelectInput from 'components/form/select/SelectInput';
import RemoteSelect from 'components/form/select/RemoteSelect';
import RichTextInput from 'components/form/richtext/RichTextInput';
import Form from 'components/form';
import Checkbox from 'components/form/checkbox/Checkbox';

import { useBusinessSectors } from 'api/businessSectorApi';

import { EmploymentTypeText, EMPLOYMENT_TYPES } from 'utils/enumMaps';

import {
	getStudentProfileFormInitialValues,
	studentProfileFormSubmit,
	StudentProfileFormValues,
} from '../profile/edit/config';

import { introFormSchemas } from './validations';

import {
	FormCommonProps,
	IntroPaper,
	IntroShadowButton,
	SectionLabel,
} from '.';

import { DescriptionPageDto, Student } from 'typing/endpoints';

const DPKey = 'Dosavadní zkušenosti';

const getjobExperienceZkusenostiContent = (student: Student): string => {
	const dp = student.descriptionPages.find(dp => dp.title === DPKey);

	return dp?.content ?? '';
};

const IntroForm4: FC<FormCommonProps> = ({ student, afterSubmit, formId }) => {
	const cache = useQueryCache();
	// Form
	const formikContext = useFormik({
		initialValues: {
			...getStudentProfileFormInitialValues(student),
			jobExperience: getjobExperienceZkusenostiContent(student),
		},
		validationSchema: introFormSchemas.form4,
		onSubmit: values => {
			const originalDp =
				student.descriptionPages.find(dp => dp.title === DPKey) ??
				(({
					title: DPKey,
					isRequired: true,
					order: 2,
					id: uuid(),
				} as unknown) as DescriptionPageDto);
			//originalDp.content = '';
			originalDp.content = values.jobExperience;

			const descriptionPages = values.descriptionPages.filter(
				dp => dp.title !== DPKey,
			);

			const newDP = [...descriptionPages, originalDp];

			const formatted: StudentProfileFormValues = {
				...omit(values, 'jobExperience'),
				descriptionPages: newDP,
			};
			try {
				studentProfileFormSubmit(async () => {
					afterSubmit(formatted);
				}, cache)(formatted);
			} catch (error) {
				console.log(error);
			}
		},
	});

	const {
		handleSubmit,
		values,
		errors,
		touched,
		isSubmitting,
		setFieldValue,
		setFieldTouched,
		submitCount,
		handleChange,
	} = formikContext;
	const wasSubmitted = submitCount !== 0;

	return (
		<Form id={formId} onSubmit={handleSubmit} width={1}>
			<Flex flexWrap="wrap" width={1} alignItems="center">
				<Flex flexDirection="column" width={1}>
					<SectionLabel label="Pracovní zkušenosti (aspoň 1)" percents={20} />
					<RichTextInput
						id="jobExperience"
						value={values.jobExperience}
						placeholder="Např. 2020 - 2021 recepční v hotelu Španělsko. Pokud nemáté žádné pracovní zkušenosti, uveďte např. projekt ze školy, na kterém jste se podílel/a"
						error={getIn(errors, 'jobExperience')}
						touched={wasSubmitted || getIn(touched, 'jobExperience')}
						disabled={isSubmitting}
						onSetValue={setFieldValue}
						onSetTouched={setFieldTouched}
						additionalButtons={false}
						isIntroductionForm
					/>
					<SectionLabel label="Oblast zájmů (aspoň 1)" percents={5} />
					<IntroPaper>
						<RemoteSelect
							id="businessSectors"
							label=""
							labelType={'aboveInput'}
							placeholder="Můžeš zvolit více možností"
							onSetValue={setFieldValue}
							onSetTouched={setFieldTouched}
							value={values.businessSectors}
							multiselect
							error={getIn(errors, 'businessSectors')}
							touched={wasSubmitted || getIn(touched, 'businessSectors')}
							disabled={isSubmitting}
							apiCall={useBusinessSectors}
							required
						/>
					</IntroPaper>
					<Flex mt={4} p={1}>
						<Checkbox
							id="wantsRemoteWork"
							name="wantsRemoteWork"
							label="Mám zájem i o práci na dálku"
							checked={values.wantsRemoteWork}
							onChange={handleChange}
							onSetTouched={setFieldTouched}
							disabled={isSubmitting}
							touched={touched.wantsRemoteWork}
						/>
					</Flex>

					<Flex
						width="100%"
						mb={3}
						alignItems="center"
						display="none!important"
					>
						{/** hidden */}
						<SelectInput
							id="employmentTypes"
							label="Typ spolupráce"
							labelType={'aboveInput'}
							placeholder="Můžeš zvolit více možností"
							onSetValue={setFieldValue}
							onSetTouched={setFieldTouched}
							value={values.employmentTypes}
							error={getIn(errors, 'employmentTypes')}
							touched={wasSubmitted || getIn(touched, 'employmentTypes')}
							disabled={isSubmitting}
							multiselect
							options={EMPLOYMENT_TYPES}
							nameFromOption={t => (t ? EmploymentTypeText[t] : '')}
						/>
					</Flex>
				</Flex>

				<Box width={1} mt={4} textAlign="center">
					<IntroShadowButton
						form={formId}
						variant="primary"
						type="submit"
						width={2 / 3}
						loading={isSubmitting}
					>
						Dokončit
					</IntroShadowButton>
				</Box>
			</Flex>
		</Form>
	);
};

export default IntroForm4;
