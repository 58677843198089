/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Box, Flex } from 'components/styled';
import { NavHrefButton, NavLinkButton } from 'components/styled/Button';
import Divider from 'components/styled/Divider';
import { HrefLink } from 'components/styled/Link';
import Text from 'components/styled/Text';
import { ResponsivePadder } from 'components/styled/Wrapper';

import { KCLogoBWIcon } from 'assets';

import { ReactComponent as LogoInverted } from 'assets/logo-inverted.svg';
import { ReactComponent as WebIcon } from 'assets/linkedin.svg';
import { ReactComponent as InstaIcon } from 'assets/insta.svg';
import { ReactComponent as FbIcon } from 'assets/fb.svg';

import { EMBED_URL, POPUP_REDICERT_PATH } from 'utils/enumMaps';

type FooterLink = {
	label: string;
	notLink?: boolean;
} & ({ notLink: true } | { to: string; external?: boolean });

type FooterLinkGroup = {
	title: FooterLink;
	links: FooterLink[];
};

const flinks: FooterLinkGroup[] = [
	{
		title: {
			label: 'Kariérní centrum',
			to: 'https://kariernicentrum.vsb.cz/cs/',
			external: true,
		},
		links: [
			{
				label: 'Akce a workshopy',
				to: 'https://kariernicentrum.vsb.cz/cs/akce-a-workshopy/',
				external: true,
			},
			{
				label: 'Individuální konzultace',
				to: 'https://kariernicentrum.vsb.cz/cs/konzultace-a-rozvoj/',
				external: true,
			},
			{
				label: 'Rozvojové programy',
				to:
					'https://kariernicentrum.vsb.cz/cs/konzultace-a-rozvoj/rozvojove-predmety-a-programy',
				external: true,
			},
			{
				label: 'Vzdělávací videa',
				to: 'https://kariernicentrum.vsb.cz/cs/pruvodce/vzdelavaci-videa/',
				external: true,
			},
			{
				label: 'k+ průvodce',
				to: 'https://kariernicentrum.vsb.cz/cs/pruvodce/',
				external: true,
			},
		],
	},
	{
		title: { label: 'k+ portál', to: '/' },
		links: [
			{
				label: 'Jak to funguje - studenti',
				to: '/how-it-works/student',
				external: false,
			},
			{
				label: 'Jak to funguje - absolventi',
				to: '/how-it-works/absolvent',
				external: false,
			},
			{
				label: 'Jak to funguje - zaměstnavatelé',
				to: '/how-it-works/company',
				external: false,
			},
		],
	},
	{
		title: {
			label: 'Pro zaměstnavatele',
			to: 'https://kariernicentrum.vsb.cz/cs/pro-zamestnavatele/',
			external: true,
		},
		links: [
			{
				label: 'Ceník a podmínky k+ portálu',
				to: '/how-it-works/company#pricelist',
			},
			{
				label: 'Veletrh pracovních příležitostí',
				to: 'https://karieraplus.vsb.cz/cs/',
				external: true,
			},
			{
				label: 'Další možnosti employer brandingu',
				to:
					'https://kariernicentrum.vsb.cz/cs/pro-zamestnavatele/dalsi-moznosti-employer-brandingu',
				external: true,
			},
		],
	},
	{
		title: { label: 'Kontakt', notLink: true },
		links: [
			{
				label: 'info.kportal@vsb.cz IČO: 61989100',
				notLink: true,
			},
			{
				label: 'Ekonomická fakulta, 17. listopadu 2172/15, Ostrava',
				notLink: true,
			},
			{
				label: '',
				notLink: true,
			},
			{
				label: 'k+ portál je oficiální kariérní portál VŠB-TUO',
				notLink: true,
			},
			{
				label: 'spravován Kariérním centrem VŠB-TUO',
				notLink: true,
			},
		],
	},
];

const Footer = () => {
	const { pathname } = useLocation();
	// hide footer if embed offers are used
	if (pathname === EMBED_URL) {
		return <></>;
	}
	if (pathname.includes(POPUP_REDICERT_PATH)) {
		return <></>;
	}
	return (
		<Flex
			as="footer"
			py={5}
			bg="secondary"
			color="white"
			flexDirection="column"
		>
			<ResponsivePadder px={2}>
				<Flex
					flexDirection={['column', 'column', 'column', 'row']}
					justifyContent="space-between"
					alignItems={['center', 'center', 'center', 'flex-start']}
					textAlign="center"
					width={1}
					fontSize={['sm']}
					//flexWrap="wrap"
				>
					{flinks.map((group, i) => (
						<Box
							key={`flink-${group.title}-${i}`}
							width={325}
							my={[4, 4, 4, 0]}
						>
							{group.title.notLink ? (
								<Text color="primary" fontWeight="bold" mb={3} fontSize="17px">
									{group.title.label}
								</Text>
							) : (
								<>
									{group.title.external ? (
										<NavHrefButton
											variant="text"
											href={group.title.to}
											color="primary"
											fontSize="17px"
											mb={0}
											p={0}
											mt={2}
											target="_blank"
											fontWeight="bold"
										>
											{group.title.label === '' ? <br /> : group.title.label}
										</NavHrefButton>
									) : (
										<NavLinkButton
											variant="text"
											to={group.title.to}
											color="primary"
											fontSize="17px"
											mb={0}
											p={0}
											mt={2}
											fontWeight="bold"
										>
											{group.title.label === '' ? <br /> : group.title.label}
										</NavLinkButton>
									)}
								</>
							)}
							{group.links.map((link, j) => (
								<Box key={`${link.label}-${j}`} my={3}>
									{link?.notLink ? (
										<Text my={0} fontSize="sm" color="white">
											{link.label === '' ? <br /> : link.label}
										</Text>
									) : (
										<>
											{link.external ? (
												<NavHrefButton
													variant="text"
													href={link.to}
													color="white"
													fontSize="sm"
													my={0}
													p={0}
													target="_blank"
												>
													{link.label === '' ? <br /> : link.label}
												</NavHrefButton>
											) : (
												<NavLinkButton
													color="white"
													variant="text"
													to={link.to}
													fontSize="sm"
													my={0}
													p={0}
												>
													{link.label === '' ? <br /> : link.label}
												</NavLinkButton>
											)}
										</>
									)}
								</Box>
							))}
						</Box>
					))}
				</Flex>
				<Divider borderColor="primary" my={4} />
				<Flex
					alignItems="center"
					justifyContent="space-between"
					flexDirection={['column', 'row']}
					width={1}
					textAlign="center"
				>
					<Flex width={325} maxWidth={325} justifyContent="center" my={[4, 0]}>
						<Flex justifyContent="space-between">
							<Box mx={1}>
								<a
									href="https://fb.com/karieraostrava/"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Facebook - Kariérní centrum VŠB-TUO"
									css={css`
										display: block;
										border-radius: 50%;
									`}
								>
									<FbIcon width={35} height={35} />
								</a>
							</Box>
							<Box mx={1}>
								<a
									href="https://www.instagram.com/karieraostrava/"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Instagram - Kariérní centrum VŠB-TUO"
									css={css`
										display: block;
										border-radius: 50%;
									`}
								>
									<InstaIcon width={35} height={35} />
								</a>
							</Box>
							<Box mx={1}>
								<a
									href="https://www.linkedin.com/showcase/karierni-centrum/"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Linkedin - Kariérní centrum VŠB-TUO"
									css={css`
										display: block;
										border-radius: 50%;
									`}
								>
									<WebIcon width={35} height={35} />
								</a>
							</Box>
						</Flex>
					</Flex>
					<Flex width={325} justifyContent="center" my={[4, 0]}>
						<HrefLink
							mt={3}
							href="https://www.vsb.cz/kariernicentrum/cs/"
							target="_blank"
						>
							<Flex
								flex={1}
								mx={4}
								py={[2, 0]}
								justifyContent="center"
								css={css`
									filter: grayscale(1) brightness(10);
								`}
							>
								<KCLogoBWIcon height={35} />
							</Flex>
						</HrefLink>
					</Flex>
					<Flex width={325} justifyContent="center" my={[4, 0]}>
						<NavLink to="/">
							<LogoInverted height="auto" width={150} />
						</NavLink>
					</Flex>
					<Flex width={325} justifyContent="center" my={[4, 0]}>
						<Box>
							<Text>Plus pro tvoji kariéru.</Text>
						</Box>
					</Flex>
				</Flex>
			</ResponsivePadder>
		</Flex>
	);
};

export default Footer;
