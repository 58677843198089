import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import DOMPurify from 'dompurify';
import BlotFormatter from 'quill-blot-formatter';
import { Quill } from 'react-quill';

import CustomVideo from 'components/form/richtext/CustomVideo';

import App from './App';
import * as serviceWorker from './serviceWorker';

// Cross-browser default styling
import 'sanitize.css';

// TODO: Upgrade normalize-url after this issue is resolved. https://github.com/sindresorhus/normalize-url/issues/105

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Accessibility testing library
if (process.env.NODE_ENV !== 'production') {
	// Axe
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const axe = require('react-axe');
	axe(React, ReactDOM, 1000);
}
// Sentry
if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://bb697ff8329144d1839199389b721689@sentry.inqool.cz/5',
		ignoreErrors: ['ChunkLoadError', 'SyntaxError'],
	});
}

// Sanitize rich text rule to allow youtube embeds
// https://github.com/cure53/DOMPurify/issues/340#issuecomment-670758980
DOMPurify.addHook('uponSanitizeElement', (node, data) => {
	if (data.tagName === 'iframe') {
		const src = node.getAttribute('src') ?? '';
		if (!src.startsWith('https://www.youtube.com/embed/')) {
			node.parentNode?.removeChild(node);
		}
	}
});
Quill.debug('error');
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register({
	'formats/video': CustomVideo,
});
