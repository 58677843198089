/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import '@reach/dialog/styles.css';
import React, { FC, Fragment, useCallback, useState } from 'react';
import Dialog from '@reach/dialog';
import { MdMenu } from 'react-icons/md';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import Divider from 'components/styled/Divider';
import { resetButtonStyle } from 'components/styled/Button';
import { NavLink } from 'components/styled/Link';
import LoaderSpin from 'components/loaders/LoaderSpin';

import { CrossIcon, ProfileIconI } from 'assets';
import { Theme } from 'theme';

import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg';

import useLogout from 'hooks/useLogout';
import useUserInfo from 'hooks/useUserInfo';

import { MenuAvatar } from './styled';

import { HeaderProps } from '.';

const ListItemCss = (theme: Theme) => css`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
		background-color: ${theme.colors.primaryLight};
	}

	&.active-route {
		font-weight: bold;
		background-color: ${theme.colors.lightGrey};

		&::after {
			content: '';
			position: absolute;
			right: 0;
			width: 5px;
			height: 100%;
			background-color: ${theme.colors.primary};
		}
	}
`;

const UserBadge: FC<Pick<HeaderProps, 'user'>> = ({ user }) => {
	const { data, isLoading } = useUserInfo(user);
	return (
		<Fragment>
			<MenuAvatar
				border
				css={css`
					cursor: default;
				`}
			>
				{isLoading && <LoaderSpin m={2} />}
				{data?.photo && (
					<img src={`/api/files/${data?.photo}`} alt="Avatar" width="100%" />
				)}
				{!isLoading && !data?.photo && <ProfileIconI size={20} />}
			</MenuAvatar>
			<Box flexGrow={1}>
				<Text ml={2}>{data?.name}</Text>
			</Box>
		</Fragment>
	);
};

type Props = Pick<HeaderProps, 'items' | 'badgeItems' | 'user'>;

const SideMenu: FC<Props> = ({ user, items, badgeItems }) => {
	const [open, setOpen] = useState(false);
	const openMenu = useCallback(() => !open && setOpen(true), [open, setOpen]);
	const closeMenu = useCallback(() => open && setOpen(false), [open, setOpen]);

	// Logout
	const handleLogOut = useLogout(user, closeMenu);

	return (
		<Fragment>
			<Box
				as="button"
				color="primary"
				onClick={openMenu}
				css={resetButtonStyle}
			>
				<MdMenu size={24} />
			</Box>
			<Dialog
				isOpen={open}
				onDismiss={closeMenu}
				aria-label="Bočné menu"
				css={css`
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					background-color: white;
					padding: 0 !important;
					margin: 0 !important;
					width: auto;
					max-width: 400px;
					min-width: 300px;

					display: flex;
					flex-direction: column;
					overflow-y: auto;
				`}
			>
				<Flex pl={5} pr={3} alignItems="center" height={82} flexShrink={0}>
					{user && <UserBadge user={user} />}
					<Flex
						as="button"
						alignItems="center"
						justifyContent="center"
						onClick={closeMenu}
						size={28}
						css={css`
							${resetButtonStyle}
							border-radius: 50%;
						`}
					>
						<CrossIcon size={18} />
					</Flex>
				</Flex>
				<Divider mx={5} my={2} borderColor="darkerGrey" />
				{items.map(item => (
					<NavLink
						key={item.title}
						to={item.to}
						onClick={closeMenu}
						css={ListItemCss}
					>
						<Text pl={5} py={3} flexGrow={1}>
							{item.title}
						</Text>
					</NavLink>
				))}
				{user && <Divider mx={5} my={2} borderColor="darkerGrey" />}
				{badgeItems.map(item => (
					<NavLink
						key={item.title}
						to={item.to}
						onClick={closeMenu}
						css={ListItemCss}
					>
						<Text pl={5} py={3} flexGrow={1}>
							{item.title}
						</Text>
						<Box mx={4} width={18}>
							{item.icon}
						</Box>
					</NavLink>
				))}
				{user && (
					<button
						onClick={handleLogOut}
						css={(theme: Theme) => css`
							${ListItemCss(theme)}
							${resetButtonStyle}
	                        padding: initial;
							font-size: initial;
							text-align: initial;
						`}
					>
						<Text pl={5} py={3} flexGrow={1}>
							Odhlásit se
						</Text>
						<Box mx={4} width={18} height="100%">
							<LogoutIcon width="100%" height="100%" />
						</Box>
					</button>
				)}
			</Dialog>
		</Fragment>
	);
};
export default SideMenu;
