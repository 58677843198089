/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

import { Flex } from 'components/styled';

import { Theme } from 'theme';

import ErrorFeedback from '../error/ErrorFeedback';

import RTButton from './RTButtons';
import { QuillContentStyles, QuillEditorStyles } from './_styles';

type Props = {
	id: string;
	placeholder?: string;

	value?: string;

	onSetValue: (id: string, value: string) => void;
	onSetTouched: (id: string, value: boolean) => void;

	error?: string | string[];
	touched?: boolean;
	disabled?: boolean;

	additionalButtons?: React.ReactNode;
	isIntroductionForm?: boolean;
};

export const sanitizeRichText = (val: string) =>
	DOMPurify.sanitize(val, {
		// Allow YT embed
		ADD_TAGS: ['iframe'],
		ADD_ATTR: [
			'allow',
			'allowfullscreen',
			'frameborder',
			'scrolling',
			'style',
			'width',
			'height',
			'class',
			'target',
		],
	});

export const EMPTY_RICH_TEXT = '<p><br></p>';

export const isRichTextEmpty = (val: string) =>
	//decode(val)
	val?.replace(/<(?!iframe)[^>]*>/g, '').trim().length <= 0;

const RichTextInput: React.FC<Props> = ({
	id,
	placeholder,
	value,
	onSetValue,
	onSetTouched,
	error,
	touched,
	disabled,
	additionalButtons,
	isIntroductionForm,
}) => (
	<Flex
		flexDirection="column"
		flexGrow={1}
		css={(theme: Theme) => css`
			${QuillContentStyles(theme)}
			${QuillEditorStyles(theme, !!error, touched, disabled)}
			${isIntroductionForm &&
			css`
				box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
				margin-bottom: 16px !important;
				.ql-container {
					padding: 0px !important;
					margin: 0px !important;
					margin-bottom: 24px !important;
				}
				.ql-editor {
					padding: 8px !important;
					margin: 0px !important;
					min-height: 150px;

					background-color: white;
				}
			`}
		`}
	>
		<Flex
			data-toolbar-for={id}
			//display={isIntroductionForm ? 'none!important' : 'unset'}
			alignItems="center"
			backgroundColor="primary"
			pr={additionalButtons ? 4 : 2}
			pl={3}
			flexWrap="wrap"
			css={css`
				white-space: nowrap;
			`}
		>
			<div className="ql-formats">
				<select
					className="ql-header"
					defaultValue=""
					onChange={e => e.persist()}
				>
					<option value="1" />
					<option value="2" />
					<option />
				</select>
			</div>
			<div className="ql-formats">
				<RTButton className="ql-bold" />
				<RTButton className="ql-italic" />
				<RTButton className="ql-underline" />
				<RTButton className="ql-link" />
				<RTButton className="ql-video" />
			</div>
			<div className="ql-formats">
				<select
					className="ql-align"
					defaultValue=""
					onChange={e => e.persist()}
				>
					<option />
					<option value="center" />
					<option value="right" />
					<option value="justify" />
				</select>
			</div>
			<div className="ql-formats">
				<RTButton className="ql-list" value="ordered" />
				<RTButton className="ql-list" value="bullet" />
			</div>
			<div className="ql-formats">
				<RTButton className="ql-clean" />
			</div>
			{additionalButtons}
		</Flex>

		<ReactQuill
			className={disabled ? 'initial' : 'notranslate'}
			theme="snow"
			value={value}
			placeholder={placeholder}
			readOnly={disabled}
			onChange={val => {
				onSetValue(id, val.replace(/&nbsp;/g, ' '));
				onSetTouched(id, true);
			}}
			onFocus={() => onSetTouched(id, true)}
			modules={{
				toolbar: {
					container: `[data-toolbar-for="${id}"]`,
				},
				clipboard: {
					matchVisual: false,
				},
				blotFormatter: {},
			}}
			formats={[
				'header',
				'bold',
				'italic',
				'underline',
				'align',
				'list',
				'bullet',
				'indent',
				'link',
				'video',
			]}
			css={css`
				display: flex;
				flex-grow: 1;
			`}
		/>

		{error && touched && (
			<ErrorFeedback
				css={(theme: Theme) => css`
					margin: ${theme.space[3]}px;
					margin-top: -${theme.space[3]}px;
				`}
			>
				{Array.isArray(error) ? error.join('. ') : error}
			</ErrorFeedback>
		)}
	</Flex>
);

export default RichTextInput;
