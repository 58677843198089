import { responseWithData } from 'utils';

import { api } from '.';

import { FileRef } from 'typing/endpoints';

export const getFile = <T extends FileRef | undefined>(file: T) =>
	(file ? `/api/files/${file.id}` : undefined) as T extends FileRef
		? string
		: undefined;

export const getFileUrl = (file?: FileRef | File, placeholder?: string) =>
	file instanceof File
		? URL.createObjectURL(file)
		: getFile(file) ?? placeholder;

export const postFile = async (file: File) => {
	const formData = new FormData();
	formData.append('file', file);

	return await responseWithData<FileRef>(
		api().post('files/', { body: formData }),
	);
};
