/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import '@reach/menu-button/styles.css';
import React, { FC, Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuLink } from '@reach/menu-button';
import { noop } from 'lodash-es';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { NavLink } from 'components/styled/Link';
import LoaderSpin from 'components/loaders/LoaderSpin';

import { ProfileIconI } from 'assets';

import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg';

import useUserInfo from 'hooks/useUserInfo';
import useLogout from 'hooks/useLogout';

import { MenuAvatar, MenuHeader, StyledMenuList } from './styled';

import { HeaderProps } from '.';

type Props = Required<Pick<HeaderProps, 'user' | 'badgeItems'>>;

const UserBadge: FC<Props> = ({ user, badgeItems }) => {
	const { data, isLoading } = useUserInfo(user);

	// Logout
	const handleLogOut = useLogout(user);

	return (
		<Fragment>
			<Menu>
				{({ isOpen }) => (
					<Fragment>
						<MenuAvatar
							as={MenuButton}
							aria-label="User Menu"
							border
							isOpen={isOpen}
						>
							{isLoading && <LoaderSpin m={2} />}
							{data?.photo && (
								<img
									src={`/api/files/${data?.photo}`}
									alt="Avatar"
									width="100%"
								/>
							)}
							{!isLoading && !data?.photo && <ProfileIconI size={20} />}
						</MenuAvatar>

						<StyledMenuList>
							<MenuItem as={MenuHeader} onSelect={noop}>
								{user.sub}
							</MenuItem>

							{badgeItems.map(({ title, icon, to }, index) => (
								<MenuLink key={`user-badge-item-${index}`} as={NavLink} to={to}>
									<Flex justifyContent="space-between" alignItems="center">
										<Text mr={3} my={0}>
											{title}
										</Text>
										<Box width={12}>{icon}</Box>
									</Flex>
								</MenuLink>
							))}

							<MenuItem
								onSelect={handleLogOut}
								css={css`
									&:hover {
										text-decoration: underline;
									}
								`}
							>
								<Flex justifyContent="space-between" alignItems="center">
									Odhlásit se
									<LogoutIcon />
								</Flex>
							</MenuItem>
						</StyledMenuList>
					</Fragment>
				)}
			</Menu>
		</Fragment>
	);
};

export default UserBadge;
