/** @jsxImportSource @emotion/core */
import 'react-datepicker/dist/react-datepicker.css';
import React, { useState } from 'react';
import { ClassNames } from '@emotion/core';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import cs from 'date-fns/locale/cs';

import { Flex } from 'components/styled';
import Button from 'components/styled/Button';

import { Loader } from 'modules/loader';
import useCalendarCompanyEvents from 'modules/search/company-events/useCalendarCompanyEvents';
import { CompanyEventModal } from 'modules/search/company-events/EventModal';

import { Theme } from 'theme';

// https://github.com/Hacker0x01/react-datepicker/#localization
registerLocale('cs', cs);
setDefaultLocale('cs');

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);
const TWO_MONTHS_FROM_TODAY = new Date(TODAY.getTime() + 60 * 24 * 3600 * 1000);

const addMonths = (date: Date, count = 2): Date =>
	new Date(date.getTime() + count * 30 * 24 * 3600 * 1000);

const EventCalendar = () => {
	const [monthOffset, setMonthOffset] = useState<Date>(TWO_MONTHS_FROM_TODAY);
	const response = useCalendarCompanyEvents(monthOffset);

	const [selectedDay, setSelectedDay] = useState<Date>();

	const data = response?.data ?? [];
	const highlightDates = data.map(e => new Date(e.from));

	return (
		<ClassNames<Theme>>
			{({ css, theme }) => {
				const datePickerStyle = () => css`
					.react-datepicker {
						border-radius: 0;
						background-color: white;
						width: 100%;
						border: none;
					}
					.react-datepicker__day--today {
						color: black;
						background-color: transparent;
						border: 1px solid black;
					}
					.react-datepicker__today-button {
						padding: 0;
						background-color: white;
					}

					.react-datepicker__day--selected,
					.react-datepicker__day--today--selected,
					.react-datepicker__day--keyboard-selected {
						background-color: ${theme.colors.primary};
						color: #fff;
						&:hover {
							background-color: ${theme.colors.primary};
							color: #fff;
						}
					}
					.react-datepicker__day--keyboard-selected {
						/* color: initial;
						background-color: #fff; */
					}
					.react-datepicker__tab-loop {
						display: inline-block;
					}
					.react-datepicker__header {
						background-color: white;
					}

					.react-datepicker__current-month,
					.react-datepicker-time__header,
					.react-datepicker-year-header {
						color: #000 !important;
					}
					.react-datepicker__day-name {
						color: #000 !important;
					}
					.react-datepicker__month-container {
						width: 100%;
					}
					.react-datepicker__month {
						margin: 0px;
					}
					.react-datepicker__day--highlighted,
					.react-datepicker__month-text--highlighted,
					.react-datepicker__quarter-text--highlighted,
					.react-datepicker__year-text--highlighted {
						background-color: ${theme.colors.primary};
					}
				`;

				return (
					<Flex
						position="relative"
						css={css`
							${datePickerStyle()}
							text-transform: capitalize;
							width: 100% !important;
							justify-content: center;
							background-color: white;
						`}
					>
						{selectedDay && (
							<CompanyEventModal
								day={selectedDay}
								onClose={() => setSelectedDay(undefined)}
							/>
						)}
						{response.isLoading && (
							<Flex
								position="absolute"
								bg="white"
								width={1}
								height="100%"
								zIndex={1}
								opacity={0.5}
								justifyContent="center"
								alignItems="center"
							>
								<Loader />
							</Flex>
						)}
						<DatePicker
							selected={selectedDay}
							onChange={val => {
								console.log(val);
							}}
							onMonthChange={val => setMonthOffset(addMonths(val))}
							name={name}
							placeholderText={'Kalendar akci'}
							dateFormat={'dd/MM/yyyy'}
							inline
							strictParsing
							popperPlacement="auto"
							highlightDates={highlightDates}
							disabledKeyboardNavigation
							onSelect={val => setSelectedDay(val)}
							todayButton={
								<Button width={1} py={0} mb={0} mt={2} height={40}>
									DNES
								</Button>
							}
						/>
					</Flex>
				);
			}}
		</ClassNames>
	);
};

export default EventCalendar;
