import { QueryResult, useQuery } from 'react-query';

import { responseWithData } from 'utils';
import { useUserToken } from 'auth';

import { isAdminUser } from 'auth/token';

import useInfiniteUASQuery from 'hooks/useInfiniteUASQuery';

import { UASParams, UASResult } from 'utils/UASTypes';

import { api } from '.';

import {
	Student,
	StudentDto,
	StudentResultDto,
	StudentSearchDto,
	FileRef,
	StudentUpdateDto,
	AlumnusRegistrationDto,
} from 'typing/endpoints';

// Registration

export const registerAlumnus = (json: AlumnusRegistrationDto) =>
	responseWithData<{ message: string }>(
		api({ skipToken: true }).post('students/register-alumnus', {
			json,
			throwHttpErrors: false,
		}),
		async r => {
			if (r.ok) {
				return { message: 'ok' };
			} else {
				return await r.json();
			}
		},
	);

export const studentGiveConsentGDPR = (
	wantsCommercialMessageNotifications: boolean,
) =>
	api()
		.put('students/update-vsb', {
			searchParams: { gdpr: true, wantsCommercialMessageNotifications },
		})
		.json<Student>();

// Student

const getStudent = (studentId: string) =>
	api().get(`students/${studentId}`).json<Student>();

const getStudentDto = (studentIdentifier: string) =>
	api().get(`students/${studentIdentifier}/dto`).json<StudentDto>();

export const useStudent = <T extends Student | StudentDto = Student>(
	studentId: string,
	useDto = false,
) =>
	useQuery<Student | StudentDto>(
		['student', studentId, useDto],
		async (_key: string, id: string, useDto: boolean) => {
			if (!id) {
				return await new Promise<Student | StudentDto>((_, reject) => reject());
			}
			return await (useDto ? getStudentDto(id) : getStudent(id));
		},
	) as QueryResult<T, unknown>;

// Students

/* const getStudents = (json: UASParams) =>
	api().post('students/parametrized', { json }).json<UASResult<Student>>(); */

const getStudents = (json: UASParams) =>
	api()
		.post('students/dto-with-submitted-forms', { json })
		.json<UASResult<Student>>();

const getStudentDtos = (json: UASParams) =>
	api().post('students/dto', { json }).json<UASResult<StudentDto>>();

export const useStudents = (json: UASParams) => {
	const token = useUserToken();
	const useDto = !isAdminUser(token);
	return useInfiniteUASQuery<Student | StudentDto, [UASParams, boolean]>(
		['students', json, useDto],
		(_key, params, useDto, page = 0) =>
			useDto
				? getStudentDtos({ ...params, page })
				: getStudents({ ...params, page }),
	);
};

// Company

export const useSearchStudents = (
	json?: StudentSearchDto,
	page?: number,
	pageSize = 9,
) =>
	useQuery(
		['students/search', json, page, pageSize],
		async (_key: string, json: StudentSearchDto) =>
			json
				? await api()
						.post(`students/search?page=${page ?? 1}&size=${pageSize}`, {
							json,
						})
						.json<UASResult<StudentResultDto>>()
				: ({ count: 0, items: [] } as UASResult<StudentResultDto>),
	);

export const revealStudent = (studentIdentifier: string, companyId: string) =>
	responseWithData(
		api().get(`students/${studentIdentifier}/reveal-for-company/${companyId}`),
		async r => r.ok && Boolean(await r.json()),
	);

// Edit

export const updateStudentProfile = (json: StudentUpdateDto) =>
	api().put('students/update-profile', { json });

export const generateStudentCV = (id: string, submittedFormId?: string) =>
	api()
		.put(`students/${id}/generate/cv`, {
			searchParams: submittedFormId
				? {
						exportTo: 'DOCX',
						submittedFormId: submittedFormId,
				  }
				: { exportTo: 'DOCX' },
		})
		.json<FileRef>();

export const generateAllCVofCandidates = (offerId: string) =>
	api().put(`students/generate-all-cv-of-offer/${offerId}`, {
		searchParams: { exportTo: 'DOCX' },
	});

export const generateStudentCVByIndentifier = (
	studentIdentifier: string,
	submittedFormId?: string,
) =>
	api()
		.put(`students/identifier/${studentIdentifier}/generate/cv`, {
			searchParams: submittedFormId
				? {
						exportTo: 'DOCX',
						submittedFormId: submittedFormId,
				  }
				: { exportTo: 'DOCX' },
		})
		.json<FileRef>();

// Activation

export const studentsActivate = (studentIdentifier: string) =>
	api().put(`students/${studentIdentifier}/activate`);

export const studentsDeactivate = (studentIdentifier: string) =>
	api().put(`students/${studentIdentifier}/deactivate`);

// Admin

export const exportStudents = (params: UASParams) =>
	api()
		.post(`students/export`, { json: { ...params, pageSize: -1 } })
		.json<FileRef>();

export const addStudentToFavourites = async (identifier: string) =>
	(
		await api().put(`students/${identifier}/add-student-to-favorite`, {
			throwHttpErrors: false,
		})
	)?.ok;

export const removeStudentFromFavourites = async (identifier: string) =>
	(
		await api().delete(`students/${identifier}/remove-student-from-favorite`, {
			throwHttpErrors: false,
		})
	)?.ok;
