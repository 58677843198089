import { addDays } from 'date-fns';
import cs from 'date-fns/locale/cs';
import { getIn, useFormik } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import ReactDatePicker, {
	registerLocale,
	setDefaultLocale,
} from 'react-datepicker';
import { FaEye } from 'react-icons/fa';
import { MdInfoOutline, MdWarning } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import SubButton from 'components/floatingactions/SubButton';
import Form from 'components/form';
import { Input, InputWrapper } from 'components/form/input/TextInput';
import ModalDialog, { ModalDialogDefaultBody } from 'components/modal';
import { Box, Flex } from 'components/styled';
import Button, { NavLinkButton } from 'components/styled/Button';
import Divider from 'components/styled/Divider';
import Text from 'components/styled/Text';

import useToggleCompanyEventActivate from 'hooks/useToggleCompanyEventActivate';
import useLatestCompanyLicence from 'hooks/useLatestCompanyLicence';
import useOfferActivate, { useHasEnoughCredits } from 'hooks/useOfferActivate';

import { getFormattedEventDate } from 'utils/UASHelpers';

import { isAfterLicenseExpiration } from './CompanyEventForm';

import { CompanyEvent } from 'typing/endpoints';

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

registerLocale('cs', cs);
setDefaultLocale('cs');

export const isPastEvent = (to: Date): boolean => {
	const toDate = new Date(to);
	toDate.setHours(0, 0, 0, 0);
	return TODAY.getTime() > toDate.getTime();
};

const getInitialDate = (from: Date): Date => {
	const ffrom = new Date(from);
	ffrom.setHours(0, 0, 0, 0);
	const activateFrom = new Date(addDays(new Date(ffrom), -29));
	activateFrom.setHours(0, 0, 0, 0);
	if (activateFrom.getTime() < TODAY.getTime()) {
		return TODAY;
	}
	return activateFrom;
};

const getInitialActiveToDate = (to: Date): Date =>
	new Date(new Date(addDays(new Date(to), 0)));

const getActivateCreditPrice = (
	activateFrom: Date,
	to: Date,
): { price: number; daysDuration: number } => {
	if (!activateFrom || !to) {
		return { price: 0, daysDuration: 0 };
	}

	const fromDate = new Date(activateFrom);
	fromDate.setHours(0, 0, 0, 0);
	const toDate = new Date(to);
	toDate.setHours(0, 0, 0, 0);
	const fromTime = fromDate.getTime();
	const toTime = addDays(new Date(toDate), 1).getTime();
	const timeDuration = toTime - fromTime;
	const daysDuration = Math.ceil(timeDuration / 1000 / 3600 / 24);
	if (daysDuration < 1) {
		return { price: 1, daysDuration: 1 };
	}
	const price = Math.ceil(daysDuration / 30);
	return {
		daysDuration,
		price: price,
	};
};

const CompanyEventActivateDialog: FC<
	CompanyEvent & { afterSubmit?: () => void; isSubbutton?: boolean }
> = ({
	id,
	activateFrom: currentActivateFrom,
	activeTo: currentActiveTo,
	from,
	to,
	afterSubmit,
	isSubbutton,
}) => {
	const toggleEventActivate = useToggleCompanyEventActivate(id);
	const { latestLicense } = useLatestCompanyLicence();
	const [disable, disabledTitle] = useOfferActivate('event');
	const { push } = useHistory();
	const activeTo = useMemo(() => getInitialActiveToDate(to), [to]);

	const formikContext = useFormik<{ activateFrom: Date; activeTo: Date }>({
		initialValues: {
			activateFrom: getInitialDate(from),
			activeTo,
		},
		onSubmit: async values => {
			const resp = await toggleEventActivate(
				values.activateFrom,
				values.activeTo,
			);
			if (resp) {
				if (afterSubmit) {
					afterSubmit();
				} else {
					push(`/company-event/${id}`);
				}
			}
		},
		validationSchema: Yup.object({
			activateFrom: Yup.date().required('Požadované').typeError('Požadované'),
		}),
	});

	const {
		values,
		errors,
		setFieldValue,
		isSubmitting,
		handleSubmit,
		handleBlur,
		resetForm,
	} = formikContext;

	useEffect(() => {
		resetForm({
			values: {
				activateFrom: getInitialDate(from),
				activeTo: getInitialActiveToDate(to),
			},
		});
	}, [from, resetForm, to]);

	const { price, daysDuration } = getActivateCreditPrice(
		values.activateFrom,
		values.activeTo,
	);
	const { notEnough, credits } = useHasEnoughCredits(price);

	return (
		<ModalDialog
			label=""
			control={openModal => (
				<>
					{isSubbutton ? (
						<SubButton
							icon={<FaEye size={20} color="white" />}
							size={40}
							type="submit"
							tooltip={disabledTitle ?? 'Aktivovat'}
							onClick={openModal}
							backgroundColor="primary"
							disabled={
								currentActivateFrom !== undefined ||
								disable ||
								isAfterLicenseExpiration(latestLicense, to)
							}
						/>
					) : (
						<Button
							py={[2, 2, 1]}
							width={[1, 1, 'unset']}
							type="submit"
							onClick={openModal}
							backgroundColor="primary"
							disabled={
								currentActivateFrom !== undefined ||
								disable ||
								isAfterLicenseExpiration(latestLicense, to)
							}
						>
							<Text as="span" mr={2}>
								{disabledTitle ?? 'Aktivovat'}
							</Text>
							<FaEye size={20} color="white" />
						</Button>
					)}
				</>
			)}
		>
			{closeModal => (
				<ModalDialogDefaultBody title="Zveřejnění akce" closeModal={closeModal}>
					{isPastEvent(to) ? (
						<>
							Akce nejde zveřejnit. Akce již proběhla. Změnte datum konce akce a
							potom zkuste akci zveřejnit znovu.
						</>
					) : (
						<Form onSubmit={handleSubmit}>
							<Box width={1} mb={4}>
								<Flex
									width={1}
									mb={2}
									color="textLight"
									flexDirection={['column', 'column', 'column', 'row']}
								>
									<Text my={0} fontSize="sm">
										Začátek akce: <b>{getFormattedEventDate(from)}</b>
									</Text>
									<Text ml={[0, 0, 0, 3]} my={0} fontSize="sm">
										Konec akce: <b>{getFormattedEventDate(to)}</b>
									</Text>
								</Flex>
								<Flex
									flexDirection={['column', 'column', 'column', 'row']}
									mt={3}
								>
									<Flex minWidth={120} pr={3} flexShrink={0}>
										<Text>
											Zveřejnit akci od:
											<Text as="span" color="error">
												*
											</Text>
										</Text>
									</Flex>

									<Flex flexDirection={['column', 'column', 'column', 'row']}>
										<InputWrapper
											hasError={getIn(errors, 'activateFrom')}
											alignItems="center"
											width={['100%', '100%', '100%', 100]}
										>
											<ReactDatePicker
												customInput={<Input autoComplete="off" />}
												selected={
													values.activateFrom
														? new Date(values.activateFrom)
														: undefined
												}
												locale={cs}
												name="activateFrom"
												id="activateFrom"
												onBlur={handleBlur}
												placeholderText="Zveřejnit od"
												dateFormat="dd.MM.yyyy"
												onChange={(val: Date | null) =>
													setFieldValue('activateFrom', val)
												}
												strictParsing
												popperPlacement="auto"
												disabledKeyboardNavigation
												minDate={TODAY}
												//maxDate={new Date(to)}
												disabled={currentActivateFrom !== undefined}
												autoComplete="off"
											/>
											<Text my={0} fontSize="sm" color="error">
												{getIn(errors, 'activateFrom')}
											</Text>
										</InputWrapper>
										<Flex minWidth={120} pr={3} flexShrink={0}>
											<Text>
												Zveřejnit akci do:
												<Text as="span" color="error">
													*
												</Text>
											</Text>
										</Flex>
										<InputWrapper
											hasError={getIn(errors, 'activeTo')}
											alignItems="center"
											width={['100%', '100%', '100%', 100]}
										>
											<ReactDatePicker
												customInput={<Input autoComplete="off" />}
												selected={
													values.activeTo
														? new Date(values.activeTo)
														: undefined
												}
												locale={cs}
												name="activeTo"
												id="activeTo"
												onBlur={handleBlur}
												placeholderText="Zveřejnit do"
												dateFormat="dd.MM.yyyy"
												onChange={(val: Date | null) =>
													setFieldValue('activeTo', val)
												}
												strictParsing
												popperPlacement="auto"
												disabledKeyboardNavigation
												minDate={
													values.activateFrom ?? currentActivateFrom ?? TODAY
												}
												// maxDate={new Date(to)}
												disabled={currentActiveTo !== undefined}
												autoComplete="off"
											/>
											<Text my={0} fontSize="sm" color="error">
												{getIn(errors, 'activeTo')}
											</Text>
										</InputWrapper>
										<Text ml={[0, 0, 0, 3]} mt={[3, 3, 3, 2]}>
											počet dnů: <b>{daysDuration}</b>
										</Text>
									</Flex>
								</Flex>

								<Flex
									mt={3}
									justifyContent="space-between"
									flexDirection={['column', 'column', 'column', 'row']}
								>
									<Text color={notEnough ? 'error' : 'text'}>
										Počet využitých kreditů: <b>{price}</b>{' '}
										{notEnough && '( nemáte dostatek kreditů )'}
									</Text>
									<Button
										variant="primary"
										type="submit"
										loading={isSubmitting}
										disabled={isSubmitting || notEnough}
									>
										Zveřejnit akci
									</Button>
								</Flex>
								<Box mt={0} fontSize="sm">
									<Text>
										Aktuálně dostupných kreditů:{' '}
										<b>{credits === -1 ? '∞' : credits}</b>{' '}
									</Text>
								</Box>
								{latestLicense?.validTo &&
									to &&
									isAfterLicenseExpiration(latestLicense, to) && (
										<Flex width={1} flexDirection="column" fontSize="sm" mt={2}>
											<Flex color="warning" alignItems={['center']}>
												<Box pr={1}>
													<MdWarning size={20} />
												</Box>
												<Text ml={1}>
													Platnost Vaší licence vyprší{' '}
													<b>
														{new Date(latestLicense.validTo).toLocaleDateString(
															'cs',
														)}
													</b>
													. Akci není možné aktivovat.
												</Text>
											</Flex>
											<Box>
												<NavLinkButton
													variant="primary"
													to="/orders/new"
													target="_blank"
													py={2}
												>
													Prodloužit licenci - objednat nový balíček
												</NavLinkButton>
											</Box>
										</Flex>
									)}

								<Divider
									height={1}
									mt={4}
									mb={1}
									borderColor="rgba(0,0,0,0.1)"
								/>
								<MdInfoOutline />
								<Text fontSize="sm" color="textLight" mt={0}>
									Zveřejnění akce se chová podobně jako zveřejnění nabídky -
									potřebuje k tomu <b>1 kredit</b>, akce bude viditelná{' '}
									<b>30 dnů</b> a automaticky se deaktivuje den po konání. Pro
									dosáhnutí nejlepšího účinku je tedy nejlepší akci zveřejnit 30
									dní před jejím konáním. Zveřejnění akce můžete však dopředu
									naplánovat nebo také zveřejnit dříve - v tom případě si
									zveřejnění vezme další kredit dle toho, jak dlouho bude
									aktivní.
								</Text>
							</Box>
						</Form>
					)}
				</ModalDialogDefaultBody>
			)}
		</ModalDialog>
	);
};

export default CompanyEventActivateDialog;
