/** @jsxImportSource @emotion/core */
import { css, SerializedStyles } from '@emotion/core';
import React from 'react';
import ReactQuill from 'react-quill';

import { Theme } from 'theme';

import { sanitizeRichText } from './RichTextInput';
import { QuillContentStyles } from './_styles';

type Props = {
	value?: string;
	customStyles?: (theme: Theme) => SerializedStyles;
};

const RTText: React.FC<Props> = ({ value = '', customStyles }) => (
	<ReactQuill
		className="notranslate"
		theme=""
		value={sanitizeRichText(value)}
		readOnly
		modules={{
			toolbar: [],
			clipboard: {
				matchVisual: false,
			},
		}}
		css={theme => css`
			${QuillContentStyles(theme)}
			${customStyles?.(theme)}
		`}
	/>
);

export default RTText;
