import { QueryCache } from 'react-query';
import { omit } from 'lodash-es';

import { postFile } from 'api/fileApi';
import { saveCompanyEvent } from 'api/companyEventsApi';

import createProgressToast from 'utils/progressToast';

import { CompanyEventSaveDto, vsb } from 'typing/endpoints';

export type NewCompanyEventFormValues = CompanyEventSaveDto & {
	newPhoto?: File;
	id?: string;
};

export const formatUTC = (date: Date): Date => {
	if (!date) {
		return new Date();
	}
	const offsetDate = new Date(date);
	const time = new Date(date).getTime();
	const tz = new Date(date).getTimezoneOffset() * 60000;
	return new Date(offsetDate.setTime(time - tz));
};

export const formatValues = (
	vals?: NewCompanyEventFormValues,
): NewCompanyEventFormValues => {
	if (!vals) {
		return {} as NewCompanyEventFormValues;
	}
	const { from: fromUtc, to: toUtc } = vals;
	const from = formatUTC(fromUtc);
	const to = formatUTC(toUtc);
	return { ...vals, from, to };
};

export const submitEvent = async (
	values: NewCompanyEventFormValues,
	cache: QueryCache,
	afterSubmit?: (eventId: string) => void,
) => {
	const { infoUpdate, successUpdate, errorUpdate } = createProgressToast(
		'Ukládání změn...',
	);

	const { newPhoto, ...rest } = values;
	try {
		const formattedValues = { ...rest };

		if (newPhoto) {
			infoUpdate(`Nahrávání souboru ${newPhoto.name}...`);
			const [logo, data] = await postFile(newPhoto);
			if (!logo.ok) {
				errorUpdate(
					`${logo.status}: Nepodařilo se nahrát soubor ${newPhoto.name} (${logo.statusText})`,
				);
				return;
			}

			formattedValues.photo = data;
		}

		if (values.isOnline) {
			formattedValues.address = (null as unknown) as vsb.Address;
		}

		const [response, data] = await saveCompanyEvent(
			omit(formattedValues, 'activateFrom'),
			values.id,
		);

		if (!response.ok) {
			errorUpdate(`${response.status}: ${response.statusText}`);
			return;
		}

		cache.invalidateQueries('logged-in-company');
		cache.invalidateQueries(['company-events', data.id]);
		console.log(afterSubmit);
		// Success
		afterSubmit?.(data.id);
		successUpdate('Změny uloženy');
	} catch (error) {
		errorUpdate(`Nastala chyba během ukládání akce. ${error}`);
	}
};
