import {
	useState,
	useCallback,
	useEffect,
	createContext,
	useContext,
} from 'react';

import { keepAlive } from 'api/keepAliveApi';

import useInterval from 'hooks/useInterval';

import store from 'utils/Store';

import { decodeToken, ApiUser, validateTokenExp } from './token';

const UserContext = createContext<ApiUser | undefined>(undefined);

export const useUserToken = () => useContext(UserContext);

export const useUserProvider = () => {
	// Repeating refresh every 3 minutes
	useInterval(keepAlive, 1000 * 60 * 3);

	// Init refresh
	useEffect(() => {
		keepAlive();
	}, []);

	const handleNewToken = useCallback((newToken: string) => {
		const decodedToken = decodeToken(newToken);
		const isTokenValid = validateTokenExp(decodedToken);

		// If token is invalid/expired, delete it
		if (!isTokenValid && newToken) {
			store.remove(store.keys.Token);
			return undefined;
		}

		return decodedToken ?? undefined;
	}, []);

	// Token
	const [token, setToken] = useState<ApiUser | undefined>(
		handleNewToken(store.get<string>(store.keys.Token) ?? ''),
	);

	// Handlers
	const handleTokenChange = useCallback(
		(newToken: string) => setToken(handleNewToken(newToken)),
		[handleNewToken],
	);

	useEffect(() => {
		// Watch for token change
		const watchId = store.watch(store.keys.Token, handleTokenChange);
		// Set new token
		const newToken = store.get<string>(store.keys.Token);
		// Decode token
		if (typeof newToken === 'string') {
			handleTokenChange(newToken);
		}

		return () => {
			if (watchId) {
				store.unwatch(watchId);
			}
		};
	}, [handleTokenChange]);

	return { Provider: UserContext.Provider, token, handleNewToken };
};
