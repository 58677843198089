import React, { useState } from 'react';

import { Box } from 'components/styled';
import Text from 'components/styled/Text';
import RemoteSelect from 'components/form/select/RemoteSelect';

import { useSoftskills } from 'api/softskillApi';

import { Softskill } from 'typing/endpoints';

const SoftskillsHelpDialog: React.FC = () => {
	const [studentSoftskill, setStudentSoftskill] = useState<Softskill | null>(
		null,
	);

	return (
		<Box minHeight="60vh">
			<Text mb={3} fontWeight="bold" fontSize="xl">
				Nápověda k měkkým dovednostem
			</Text>

			<Box width={1}>
				<Box>
					<RemoteSelect
						id="studentSoftskillHelp"
						placeholder="Vyberte ze seznamu"
						value={studentSoftskill}
						apiCall={useSoftskills}
						multiselect={false}
						onSetValue={(_id, value) => setStudentSoftskill(value ?? null)}
					/>
				</Box>
				<Box p={1} mt={3}>
					<Text>{studentSoftskill?.description ?? ''}</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default SoftskillsHelpDialog;
