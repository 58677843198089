import { useQuery } from 'react-query';

import { UASResult } from 'utils/UASTypes';

import { api } from '.';

import { Region } from 'typing/endpoints';

export const useRegions = () =>
	useQuery(
		'regions',
		() =>
			api({ skipToken: true })
				.get('regions', { searchParams: { pageSize: -1 } })
				.json<UASResult<Region>>(),
		{ staleTime: 3600000 },
	);
