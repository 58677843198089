import { useQuery } from 'react-query';

import { UASResult } from 'utils/UASTypes';

import { api } from '.';

import { Hardskill } from 'typing/endpoints';

export const useHardskills = () =>
	useQuery(
		'hardskills',
		() => api().get('hardskills/distinct').json<UASResult<Hardskill>>(),
		{ staleTime: 3600000 },
	);

export const updateHardskill = (hs: Hardskill) =>
	api().put(`hardskills/${hs.id}`, { json: hs });

export const deleteHardskill = (hs: Hardskill) =>
	api().delete(`hardskills/${hs.id}`);
