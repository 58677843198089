import React, {
	useState,
	useCallback,
	Fragment,
	FC,
	CSSProperties,
} from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';

import { Flex, Box } from 'components/styled';
import Text from 'components/styled/Text';
import Chip from 'components/styled/Chip';
import HelpDialog from 'components/modal/HelpDialog';

import { useLanguageList } from 'api/languageApi';

import {
	LanguageLevelDescription,
	LanguageLevelDescriptionTitle,
	LANGUAGE_LEVELS,
} from 'utils/enumMaps';

import SelectInput, {
	MultiselectProps,
	CommonSelectProps,
	MonoselectProps,
} from '../select/SelectInput';

import { LanguageSkill } from 'typing/endpoints';

type Props = Omit<
	CommonSelectProps<LanguageSkill>,
	'options' | 'reloadOptions' | 'nameFromOption' | 'loading' | 'reloadOptions'
> &
	(MonoselectProps<LanguageSkill> | MultiselectProps<LanguageSkill>) & {
		showHint?: boolean;
		levelPlaceholder?: string;
		languageSelectCustomStyles?: CSSProperties;
		languageWrapperCustomStyles?: CSSProperties;
	};

const LanguageSelect: FC<Props> = ({
	showHint,
	levelPlaceholder = 'Úroveň',
	languageSelectCustomStyles,
	languageWrapperCustomStyles,
	...props
}) => {
	const langResponse = useLanguageList();
	const languages =
		(langResponse.data?.items.flatMap(language =>
			LANGUAGE_LEVELS.map(languageLevel => ({
				language,
				languageLevel,
			})),
		) as LanguageSkill[]) ?? [];

	const [language, setLanguage] = useState<LanguageSkill | null>(null);

	const labelFromOption = useCallback(
		(l: LanguageSkill | null, level?: boolean) =>
			l ? (
				<Flex alignItems="center">
					<FlagIcon code={l.language.code as FlagIconCode} size={20} />
					<Text ml={2} my={0}>
						{l.language.name}
						{level ? ` ${l.languageLevel}` : ''}
					</Text>
				</Flex>
			) : null,
		[],
	);

	const keyFromOption = useCallback(
		(l: LanguageSkill | null) =>
			l ? `${l.language.id} ${l.languageLevel}` : '',
		[],
	);

	return (
		<Flex flexDirection="column" style={languageWrapperCustomStyles}>
			{props.multiselect && !props.hideInlineSelectItems && (
				<Flex maxWidth="100%" flexWrap="wrap" mb={2}>
					{props.value.map(v => (
						<Chip
							key={keyFromOption(v)}
							size="small"
							variant="delete"
							onDelete={() =>
								props.onSetValue(
									props.id,
									props.value.filter(val => val !== v),
								)
							}
						>
							{labelFromOption(v, true)}
						</Chip>
					))}
				</Flex>
			)}
			<Flex>
				<Box width={3 / 5} style={languageSelectCustomStyles} mr={2}>
					<SelectInput<LanguageSkill | null>
						id="languagesSelect"
						placeholder={props.placeholder ?? 'Jazyk'}
						value={language}
						onSetValue={(_id: string, value: LanguageSkill | null) =>
							setLanguage(value)
						}
						options={languages.filter(l => l.languageLevel === 'A1')}
						nameFromOption={l => l?.language.name ?? ''}
						keyFromOption={l => l?.language.id ?? ''}
						labelFromOption={labelFromOption}
						searchKeys={['language.name']}
						loading={langResponse.status === 'loading'}
						reloadOptions={langResponse.refetch}
						colorVariant={props.colorVariant}
						{...(props.multiselect
							? { hideInlineSelectItems: props.hideInlineSelectItems }
							: {})}
					/>
				</Box>
				<Flex width={2 / 5} ml={2} alignSelf="flex-end" alignItems="center">
					<SelectInput
						{...props}
						onSetValue={(...args: Parameters<typeof props.onSetValue>) => {
							setLanguage(null);
							// TODO: Fix after https://github.com/microsoft/TypeScript/issues/4130#issuecomment-690285949 is resolved
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(props as any).onSetValue(...args);
						}}
						placeholder={levelPlaceholder}
						options={languages.filter(l => l.language === language?.language)}
						colorVariant={props.colorVariant}
						nameFromOption={l =>
							l ? `${l.language.name} ${l.languageLevel}` : ''
						}
						keyFromOption={keyFromOption}
						labelFromOption={l => l?.languageLevel}
						noOptionsText="Prosím nejprve vyberte jazyk"
						{...(props.multiselect ? { hideInlineSelectItems: true } : {})}
					/>
					{showHint && (
						<HelpDialog label="Dialog vysvetlivek úrovní jazyků">
							<Text fontSize="xl" fontWeight="bold">
								Úrovně jazyků
							</Text>
							{LANGUAGE_LEVELS.map(l => (
								<Fragment key={l}>
									<Text mb={0} fontWeight="bold">
										{l} - {LanguageLevelDescriptionTitle[l]}
									</Text>
									<Text>{LanguageLevelDescription[l]}</Text>
								</Fragment>
							))}
						</HelpDialog>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};
export default LanguageSelect;
