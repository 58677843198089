import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryCache } from 'react-query';

import { ApiUser } from 'auth/token';

import store from 'utils/Store';

const useLogout = (user?: ApiUser, afterLogout?: () => void) => {
	const cache = useQueryCache();
	const { push } = useHistory();
	return useCallback(async () => {
		store.remove(store.keys.Token);
		cache.removeQueries('logged-in-company');
		if (user?.type === 'STUDENT') {
			// Logout from SSO
			window.location.replace('/api/login/cas-logout');
		} else {
			push('/');
		}
		afterLogout?.();
	}, [cache, user, afterLogout, push]);
};

export default useLogout;
