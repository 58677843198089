import React from 'react';
import { useQueryCache } from 'react-query';

import { RenderPopup } from 'modules/admin/settings/popup-windows/popup-preview';

import { markPopupAsShown } from 'api/popupWindowsApi';

import Store from 'utils/Store';

import useLoggedInPopup from './hooks/useLoggedInPopup';

import { PopUpWindow } from 'typing/endpoints';

const RenderAssignedPopupWindow = () => {
	const response = useLoggedInPopup();
	const cache = useQueryCache();

	if (response.isLoading || response.isError) {
		return null;
	}

	const popups = response.data ?? [];
	if (popups.length > 0) {
		Store.set(Store.keys.CurrentPopupId, popups[0].assignedPopUpWindowId);
		return (
			<RenderPopup
				popup={(popups[0] as unknown) as PopUpWindow}
				closeModal={async () => {
					Store.remove(Store.keys.CurrentPopupId);
					await markPopupAsShown(popups[0].assignedPopUpWindowId);
					cache.invalidateQueries(['logged-in', 'assigned-popup']);
				}}
			/>
		);
	}

	return null;
};

export default RenderAssignedPopupWindow;
