/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import cs from 'date-fns/locale/cs';
import { getIn, useFormik } from 'formik';
import React, { FC, useMemo } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { MdPhotoCamera, MdWarning } from 'react-icons/md';
import { useQueryCache } from 'react-query';

import Form from 'components/form';
import FileInput, { FileAccept } from 'components/form/file/FileInput';
import TextInput, {
	Input,
	InputWrapper,
} from 'components/form/input/TextInput';
import RichTextInput from 'components/form/richtext/RichTextInput';
import RemoteSelect from 'components/form/select/RemoteSelect';
import { Box, Flex } from 'components/styled';
import Paper from 'components/styled/Paper';
import Text from 'components/styled/Text';
import CompanyAddressInput from 'components/form/address/CompanyAddressInput';
import { NavLinkButton } from 'components/styled/Button';
import RadioInput from 'components/form/radio/RadioInput';

import CompanyCover from 'modules/company/profile/edit/CompanyCover';

import { OverlayCss } from 'theme';
import { normalizeUrl } from 'utils';

import logoPlaceholder from 'assets/company-avatar-placeholder.png';

import { getFileUrl } from 'api/fileApi';
import { useBusinessSectors } from 'api/businessSectorApi';

import useBreakpoint from 'hooks/useBreakpoint';
import useLatestCompanyLicence from 'hooks/useLatestCompanyLicence';

import { ADDRESS_INIT_VALUE } from 'utils/address';

import { formatValues, NewCompanyEventFormValues, submitEvent } from './config';
import { companyEventValidationSchema } from './validation';

import { CompanyEvent, FileRef, PurchasedProduct } from 'typing/endpoints';

registerLocale('cs', cs);
setDefaultLocale('cs');

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

export const isAfterLicenseExpiration = (
	license: PurchasedProduct | undefined,
	to: Date,
): boolean => {
	if (!license || !to) {
		return false;
	}
	const validTo = new Date(license.validTo);
	const toDate = new Date(to);
	validTo.setHours(0, 0, 0, 0);
	toDate.setHours(0, 0, 0, 0);

	return validTo.getTime() < toDate.getTime();
};

const INITIAL_VALUES: NewCompanyEventFormValues = {
	title: '',
	address: ADDRESS_INIT_VALUE,
	businessSectors: [],
	description: '',
	descriptionPages: [],
	eventUrl: 'https://',
	from: new Date(),
	to: new Date(),
	photo: (null as unknown) as FileRef,
	newPhoto: (null as unknown) as File,
	isOnline: false,
};

const INPUT_SHARED_MIN_LABEL_WIDTH = '120px';

type Props = {
	companyEvent?: CompanyEvent;
	formId: string;
	afterSubmit?: (eventId: string) => void;
};

const CompanyEventForm: FC<Props> = ({ companyEvent, formId, afterSubmit }) => {
	const isMobile = useBreakpoint(0);
	const cache = useQueryCache();
	// Form
	const formikContext = useFormik<NewCompanyEventFormValues>({
		initialValues: { ...INITIAL_VALUES, ...formatValues(companyEvent) },
		onSubmit: values => submitEvent(values, cache, afterSubmit),
		validationSchema: companyEventValidationSchema,
	});

	const { latestLicense } = useLatestCompanyLicence();

	const {
		values,
		errors,
		touched,
		setFieldValue,
		isSubmitting,
		setFieldTouched,
		handleSubmit,
		submitCount,
		handleChange,
		handleBlur,
		validateField,
		setFieldError,
	} = formikContext;

	const photoUrl = useMemo(
		() => getFileUrl(values?.newPhoto ?? values.photo ?? logoPlaceholder),
		[values?.newPhoto, values.photo],
	);

	const onUrlBlur = (id: string) => (
		event: React.FocusEvent<HTMLInputElement>,
	) => {
		handleBlur(event);
		const newVal = normalizeUrl(event.target.value, { forceHttps: true });

		if (event.target.value && newVal) {
			setFieldValue(id, newVal);
		}
		if (event.target.value && !newVal) {
			setFieldError(id, 'Neplatná adresa');
		}
	};

	const wasSubmitted = submitCount !== 0;

	return (
		<Form onSubmit={handleSubmit} mb={5} id={formId}>
			<Paper>
				<CompanyCover photo={photoUrl}>
					<FileInput
						id="newPhoto"
						label="Nahrát novou fotku"
						value={values?.newPhoto ?? null}
						error={getIn(errors, 'newPhoto')}
						touched={getIn(touched, 'newPhoto')}
						onSetValue={(id: string, value: File | null) => {
							setFieldValue(id, value);
							validateField('newPhoto');
						}}
						onSetTouched={setFieldTouched}
						disabled={isSubmitting}
						accept={FileAccept.Image}
						croppable={{
							aspect: 4 / 1,
						}}
						photoUrl={photoUrl}
						onFileCropped={newFile => setFieldValue('photoFile', newFile)}
						editLabel="Upravit původní fotku"
						withEditButton
					>
						{openPhoto => (
							<Box
								position="absolute"
								top={0}
								left={0}
								right={0}
								bottom={0}
								css={
									getIn(errors, 'newPhoto')
										? css`
												border: 1px solid red;
										  `
										: css``
								}
							>
								<Flex onClick={openPhoto} css={OverlayCss}>
									<MdPhotoCamera size={36} />
									<Text fontSize="xl" mt={2} mb={0}>
										Nahrát / upravit fotku
									</Text>
								</Flex>
							</Box>
						)}
					</FileInput>
				</CompanyCover>
				{getIn(errors, 'newPhoto') && (
					<Flex alignSelf="flex-end" justifySelf="bottom" color="error">
						Požadované
					</Flex>
				)}
				{companyEvent && (
					<Flex my={4}>
						<Box
							px={2}
							bg={companyEvent.isVisible ? 'primary' : 'darkerGrey'}
							boxShadow={`0px 0px 2px rgba(0,0,0,0.5)`}
						>
							Zveřejněná: <b>{companyEvent.isVisible ? 'Ano' : 'Ne'}</b>{' '}
						</Box>
						<Box
							px={2}
							ml={2}
							bg={companyEvent.activateFrom ? 'primary' : 'darkerGrey'}
							boxShadow={`0px 0px 2px rgba(0,0,0,0.5)`}
						>
							{companyEvent.isVisible
								? 'Zveřejněné od: '
								: 'Zveřejnění naplánováno: '}
							<b>
								{companyEvent.activateFrom
									? new Date(companyEvent.activateFrom)?.toLocaleDateString()
									: 'Ne'}
							</b>{' '}
						</Box>
						{companyEvent.activeTo && (
							<Box
								px={2}
								ml={2}
								bg="primary"
								boxShadow={`0px 0px 2px rgba(0,0,0,0.5)`}
							>
								{companyEvent.isVisible
									? 'Zveřejněné do: '
									: 'Zveřejnění naplánováno do: '}
								<b>{new Date(companyEvent.activeTo)?.toLocaleDateString()}</b>{' '}
							</Box>
						)}
						<Box
							ml={2}
							px={2}
							bg="warning"
							boxShadow={`0px 0px 2px rgba(0,0,0,0.5)`}
						>
							Kliknutí: <b>{companyEvent.urlClickCounter}</b>
						</Box>
					</Flex>
				)}
				<Box width={1} my={4}>
					<TextInput
						id="title"
						label="Název akce"
						required
						labelType={isMobile ? 'aboveInput' : 'leftToInput'}
						placeholder="Napište svůj název akce"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.title}
						error={getIn(errors, 'title')}
						touched={wasSubmitted || getIn(touched, 'title')}
						disabled={isSubmitting}
						labelMinWidth={INPUT_SHARED_MIN_LABEL_WIDTH}
					/>
				</Box>
				<Box width={1} my={4}>
					<TextInput
						id="eventUrl"
						label="Odkaz na akci"
						required
						labelType={isMobile ? 'aboveInput' : 'leftToInput'}
						placeholder="Napište svůj název akce"
						onChange={handleChange}
						onBlur={onUrlBlur('eventUrl')}
						value={values.eventUrl}
						error={getIn(errors, 'eventUrl')}
						touched={wasSubmitted || getIn(touched, 'eventUrl')}
						disabled={isSubmitting}
						labelMinWidth={INPUT_SHARED_MIN_LABEL_WIDTH}
					/>
				</Box>
				<Flex
					width={1}
					my={4}
					flexDirection={['column', 'column', 'column', 'row']}
				>
					<Flex flexDirection={['column', 'column', 'column', 'row']}>
						<Flex minWidth={120} pr={4} flexShrink={0}>
							<Text>
								Začátek akce:
								<Text as="span" color="error">
									*
								</Text>
							</Text>
						</Flex>
						<InputWrapper hasError={getIn(errors, 'from')} alignItems="center">
							<DatePicker
								customInput={<Input />}
								selected={values.from ? new Date(values.from) : undefined}
								locale={cs}
								name="from"
								id="from"
								onBlur={handleBlur}
								placeholderText={'Datum začátku akce'}
								dateFormat="dd.MM.yyyy HH:mm"
								onChange={(val: Date | null) => setFieldValue('from', val)}
								strictParsing
								popperPlacement="auto"
								disabledKeyboardNavigation
								timeInputLabel="Čas začátku:"
								showTimeInput
								timeFormat="HH:mm"
								disabled={
									isSubmitting || companyEvent?.activateFrom !== undefined
								}
							/>
							<Text my={0} fontSize="sm" color="error">
								{getIn(errors, 'from')}
							</Text>
						</InputWrapper>
					</Flex>
					<Flex
						flexDirection={['column', 'column', 'column', 'row']}
						mt={[3, 3, 3, 0]}
					>
						<Flex minWidth={120} pr={4} pl={[0, 0, 0, 4]} flexShrink={0}>
							<Text>
								Konec akce:
								<Text as="span" color="error">
									*
								</Text>
							</Text>
						</Flex>
						<InputWrapper hasError={getIn(errors, 'to')} alignItems="center">
							<DatePicker
								customInput={<Input />}
								selected={values.to ? new Date(values.to) : undefined}
								locale={cs}
								name="to"
								id="to"
								onBlur={handleBlur}
								placeholderText={'Datum konce akce'}
								dateFormat="dd.MM.yyyy HH:mm"
								onChange={(val: Date | null) => setFieldValue('to', val)}
								strictParsing
								popperPlacement="auto"
								disabledKeyboardNavigation
								timeInputLabel="Čas konce:"
								showTimeInput
								timeFormat="HH:mm"
								disabled={
									isSubmitting || companyEvent?.activateFrom !== undefined
								}
								minDate={TODAY}
							/>
							<Text my={0} fontSize="sm" color="error">
								{getIn(errors, 'to')}
							</Text>
						</InputWrapper>
					</Flex>
				</Flex>
				{latestLicense?.validTo &&
					values.to &&
					isAfterLicenseExpiration(latestLicense, values.to) && (
						<Flex
							width={1}
							justifyContent="space-between"
							flexDirection={['column', 'column', 'column', 'row']}
						>
							<Flex color="warning" alignItems={['center']}>
								<Box pr={2}>
									<MdWarning size={20} />
								</Box>
								<Text ml={2}>
									UPOZORNĚNÍ: Platnost Vaší licence vyprší{' '}
									<b>
										{new Date(latestLicense.validTo).toLocaleDateString('cs')}
									</b>
									. Akci nebude možné aktivovat.
								</Text>
							</Flex>
							<Box>
								<NavLinkButton
									variant="primary"
									to="/orders/new"
									target="_blank"
									py={2}
								>
									Prodloužit licenci - objednat nový balíček
								</NavLinkButton>
							</Box>
						</Flex>
					)}

				<Box width={1} my={4}>
					<RemoteSelect
						apiCall={useBusinessSectors}
						id="businessSectors"
						label="Obor zájmu"
						required
						labelType={isMobile ? 'aboveInput' : 'leftToInput'}
						placeholder="Můžete zvolit více možností"
						onSetValue={setFieldValue}
						onSetTouched={setFieldTouched}
						value={values.businessSectors}
						error={getIn(errors, 'businessSectors')}
						touched={wasSubmitted || getIn(touched, 'businessSectors')}
						disabled={isSubmitting}
						multiselect
						labelMinWidth={INPUT_SHARED_MIN_LABEL_WIDTH}
					/>
				</Box>
				<Box width={1} my={4}>
					<Text fontWeight="bold">Místo konání:</Text>
					<Flex maxWidth={300} my={3}>
						<RadioInput
							flexDirection="row"
							width={1}
							id="isOnline"
							onSetValue={(id, val) => setFieldValue(id, val)}
							value={values.isOnline}
							options={[
								{ value: false, label: 'Osobně' },
								{ value: true, label: 'Online' },
							]}
						/>
					</Flex>

					{!values.isOnline && (
						<CompanyAddressInput
							attr="address"
							values={values.address}
							errors={errors.address}
							touched={touched.address}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							wasSubmitted={wasSubmitted}
							isSubmitting={isSubmitting}
							loadingAres={false}
						/>
					)}
				</Box>
				<Box width={1} my={4}></Box>
				<Text fontWeight="bold">Popis akce:</Text>
				<RichTextInput
					id="description"
					value={values.description}
					placeholder="popis akce"
					error={getIn(errors, 'description')}
					touched={wasSubmitted || getIn(touched, 'description')}
					disabled={isSubmitting}
					onSetValue={setFieldValue}
					onSetTouched={setFieldTouched}
				/>
			</Paper>
		</Form>
	);
};

export default CompanyEventForm;
