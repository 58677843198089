import { ApiUser, isAdminUser, isRecruiterUser } from 'auth/token';

export const getCompanyIdByUserType = (user?: ApiUser): string => {
	if (isRecruiterUser(user)) {
		return user.companyId;
	}
	return '';
};

export const checkProfileEditable = (
	user: ApiUser | undefined,
	companyId: string,
) => (isRecruiterUser(user) ? user.companyId === companyId : isAdminUser(user));
