/** @jsxImportSource @emotion/core */
import { css, SerializedStyles } from '@emotion/core';
import React, { ComponentProps, FC, useMemo, useRef, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';

import Chip from 'components/styled/Chip';
import { Flex } from 'components/styled';

import { Theme } from 'theme';

import useClickaway from 'hooks/useClickaway';

type Props = ComponentProps<typeof Chip> & {
	color?: 'inverted' | 'primary' | 'error';
	wrapperCss?: SerializedStyles;
};

const ItemLabel: FC<Props> = ({ color, wrapperCss, ...props }) => {
	const ref = useRef<HTMLDivElement>(null);
	const refCurrent = ref.current;
	const hasEllipsis = useMemo(
		() => refCurrent && refCurrent.offsetWidth < refCurrent.scrollWidth,
		[refCurrent],
	);
	return (
		<Chip
			ref={ref}
			size="small"
			variant="default"
			title={hasEllipsis ? ref.current?.innerText : undefined}
			{...props}
			css={(theme: Theme) => css`
				display: block;
				max-width: 50%;

				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				flex-shrink: 0;

				${!color &&
				css`
					background-color: white;
					color: ${theme.colors.text};
				`}

				${color === 'inverted' &&
				css`
					background-color: ${theme.colors.darkerGrey};
					color: white;
				`}

				${color === 'primary' &&
				css`
					background-color: ${theme.colors.primaryLight};
					color: ${theme.colors.primary};
				`}

				${color === 'error' &&
				css`
					background-color: rgba(219, 62, 62, 0.25);
					color: ${theme.colors.error};
				`}
				${wrapperCss}
			`}
		/>
	);
};

export default ItemLabel;

export const ItemLabelList: FC<
	Props & { children: string[] | JSX.Element[] }
> = ({ children: [head, ...tail], wrapperCss, ...props }) => {
	const [expanded, setExpanded] = useState(false);
	const [clickawayRef] = useClickaway<HTMLDivElement>(
		() => expanded && setExpanded(false),
	);

	if (tail.length <= 0) {
		return (
			<ItemLabel wrapperCss={wrapperCss} {...props}>
				{head}
			</ItemLabel>
		);
	}

	return (
		<Flex
			position="relative"
			ref={clickawayRef}
			onClick={() => setExpanded(e => !e)}
			maxWidth="50%"
			css={wrapperCss ? wrapperCss : css``}
		>
			<ItemLabel
				{...props}
				position="relative"
				pr={24}
				css={css`
					max-width: unset;
					flex-shrink: 1;
				`}
			>
				{head}
				<Flex
					position="absolute"
					right={0}
					top={0}
					height="100%"
					mr={2}
					alignItems="center"
				>
					<MdExpandMore
						size={18}
						transform={expanded ? 'rotate(180)' : undefined}
					/>
				</Flex>
			</ItemLabel>

			<Flex
				position="absolute"
				left={0}
				top="100%"
				flexDirection="column"
				alignItems="flex-start"
				zIndex={999}
				width="100%"
				css={css`
					visibility: ${expanded ? 'visible' : 'hidden'};
				`}
			>
				{tail.map((c, i) => (
					<ItemLabel
						key={`${i}-${c}`}
						css={css`
							max-width: 125%;
							box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);

							transition: all 500ms;
							opacity: ${expanded ? 1 : 0};
							pointer-events: none;
							${!expanded &&
							css`
								margin-top: -31px !important;
							`}
						`}
					>
						{c}
					</ItemLabel>
				))}
			</Flex>
		</Flex>
	);
};
