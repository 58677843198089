import { useQuery } from 'react-query';

import { api } from '.';

import { PurchasedProduct } from 'typing/endpoints';

export const useCompanyPurchasedProducts = (
	companyId: string,
	disabled?: boolean,
) =>
	useQuery(
		['purchased-products/company/', companyId],
		(_key: string, id: string) =>
			api().get(`purchased-products/company/${id}`).json<PurchasedProduct[]>(),
		{ enabled: !disabled },
	);
