/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import '@reach/dialog/styles.css';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import Dialog from '@reach/dialog';
import { MdEmail, MdMenu, MdPhone } from 'react-icons/md';
import { FiExternalLink } from 'react-icons/fi';
import { IoMdLogOut } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import Divider from 'components/styled/Divider';
import { NavLinkButton, resetButtonStyle } from 'components/styled/Button';
import { HrefLink, NavLink } from 'components/styled/Link';
import LoaderSpin from 'components/loaders/LoaderSpin';
import EventCalendar from 'components/form/input/DateInput/EventCalendar';

import { carts } from 'modules/student/dashboard/leftPanel/StudentDashboardLeftPanel';

import { ProfileIconI } from 'assets';
import { Theme } from 'theme';

import useLogout from 'hooks/useLogout';
import useUserInfo from 'hooks/useUserInfo';
import useSlideToSide from 'hooks/useSlideToSide';

import { MenuAvatar } from './styled';

import { HeaderProps } from '.';

const ListItemCss = (theme: Theme) => css`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;

	&:hover {
		text-decoration: underline;
		background-color: ${theme.colors.primaryLight};
	}

	&.active-route {
		font-weight: bold;
		background-color: ${theme.colors.lightGrey};

		&::after {
			content: '';
			position: absolute;
			right: 0;
			width: 5px;
			height: 100%;
			background-color: ${theme.colors.primary};
		}
	}
`;

const UserBadge: FC<Pick<HeaderProps, 'user'> & { closeMenu: () => void }> = ({
	user,
	closeMenu,
}) => {
	const { data, isLoading } = useUserInfo(user);

	return (
		<Flex alignItems="center" my={3} flexShrink={0}>
			<Link to="/student" onClick={closeMenu}>
				<MenuAvatar
					border
					height="55px!important"
					width="55px!important"
					css={css`
						cursor: pointer;
					`}
				>
					{isLoading && <LoaderSpin m={2} />}
					{data?.photo && (
						<img
							src={`/api/files/${data?.photo}`}
							alt="Avatar"
							width="100%"
							height="100%"
						/>
					)}
					{!isLoading && !data?.photo && <ProfileIconI size={20} />}
				</MenuAvatar>
			</Link>
			<Box flexGrow={1} pl={2}>
				{/* <Text my={0} fontWeight="bold">
					{data?.name}
				</Text> */}
				<Flex flexDirection="column" mt={1} fontSize="sm">
					<Text my={0} width={1}>
						<Text as="span" color="primary" mr={2}>
							<MdEmail size={14} />
						</Text>
						{data?.email}
					</Text>

					{data?.phone ? (
						<Text my={0} width={1}>
							<Text as="span" color="primary" mr={2}>
								<MdPhone size={14} />
							</Text>
							{data?.phone}
						</Text>
					) : (
						<>
							<Text my={1} width={1} fontSize="sm">
								<NavLinkButton
									my={0}
									p={0}
									variant="text"
									to="/student/edit"
									fontSize="sm"
								>
									<Text as="span" color="primary" mr={2}>
										<MdPhone size={14} />
									</Text>
									<b>Doplnit telefoní číslo</b>
								</NavLinkButton>
							</Text>
						</>
					)}
				</Flex>
			</Box>
		</Flex>
	);
};

type Props = Pick<HeaderProps, 'items' | 'badgeItems' | 'user'>;

const SideMenuStudent: FC<Props> = ({ user, badgeItems }) => {
	const [open, setOpen] = useState(false);
	const openMenu = useCallback(() => !open && setOpen(true), [open, setOpen]);
	const closeMenu = useCallback(() => open && setOpen(false), [open, setOpen]);
	const { handleTouchStart, handleTouchMove, leftSwipe } = useSlideToSide();
	// Logout
	const handleLogOut = useLogout(user, closeMenu);
	const filteredBadgeItems = badgeItems.filter(b => b.to !== '/settings');
	const settingsBadgeItem = badgeItems.find(b => b.to === '/settings');
	useEffect(() => {
		if (leftSwipe) {
			setOpen(false);
		}
	}, [leftSwipe]);

	return (
		<Fragment>
			<Box
				as="button"
				color="primary"
				onClick={openMenu}
				css={resetButtonStyle}
			>
				<MdMenu size={24} />
			</Box>
			<Dialog
				isOpen={open}
				onDismiss={closeMenu}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				aria-label="Bočné menu"
				css={css`
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					background-color: white;
					/* padding: 0 !important; */
					padding: 0 20px;
					margin: 0 !important;
					width: auto;
					max-width: 400px;
					min-width: 300px;

					display: flex;
					flex-direction: column;
					overflow-y: auto;
				`}
			>
				{user && <UserBadge user={user} closeMenu={closeMenu} />}
				{/* <Flex>
					<NavLinkButton
						m={0}
						p={0}
						variant="text"
						to="/student/edit"
						fontSize="sm"
						fontWeight="bold"
					>
						<Text as="span" color="primary" mr={2}>
							<MdPerson />
						</Text>
						VYLEPŠIT PROFIL
					</NavLinkButton>
				</Flex> */}
				{/* <Divider my={3} borderColor="primary" size={2} /> */}
				{filteredBadgeItems.map(item => (
					<NavLink
						key={item.title}
						to={item.to}
						onClick={closeMenu}
						css={ListItemCss}
					>
						<Box width={18} mr={2} color="primary">
							{item.icon}
						</Box>
						<Text my="6px" py={0} flexGrow={1} fontSize="sm">
							{item.title}
						</Text>
					</NavLink>
				))}
				<Divider my={3} borderColor="primary" size={2} />
				{carts
					.filter(c => c.sideMenuTitle)
					.map(item => (
						<HrefLink
							key={item.sideMenuTitle}
							href={item.buttonLink ?? ''}
							onClick={closeMenu}
							css={ListItemCss}
							target="_blank"
						>
							<Box width={18} mr={2} color="primary">
								<FiExternalLink />
							</Box>
							<Text py={0} flexGrow={1} fontSize="sm" my="6px">
								{item.sideMenuTitle}
							</Text>
						</HrefLink>
					))}
				<Divider my={3} borderColor="primary" size={2} />
				<Flex width={1} justifyContent="center">
					<Text
						fontSize="sm"
						my="6px"
						css={css`
							text-transform: uppercase;
						`}
					>
						Kalendář akcí
					</Text>
				</Flex>
				<EventCalendar />
				<Divider my={3} borderColor="primary" size={2} />
				{/* {items.map(item => (
					<NavLink
						key={item.title}
						to={item.to}
						onClick={closeMenu}
						css={ListItemCss}
					>
						<Text py={0} flexGrow={1}>
							{item.title}
						</Text>
					</NavLink>
				))} */}
				{settingsBadgeItem && (
					<NavLink
						key={settingsBadgeItem.title}
						to={settingsBadgeItem.to}
						onClick={closeMenu}
						css={ListItemCss}
					>
						<Box width={18} mr={2} color="primary">
							{settingsBadgeItem.icon}
						</Box>
						<Text my="6px" py={0} flexGrow={1} fontSize="sm">
							{settingsBadgeItem.title}
						</Text>
					</NavLink>
				)}
				{user && (
					<button
						onClick={handleLogOut}
						css={(theme: Theme) => css`
							${ListItemCss(theme)}
							${resetButtonStyle}
	                        padding: initial;
							font-size: initial;
							text-align: initial;
						`}
					>
						<Box width={18} mr={2} color="primary">
							<IoMdLogOut width="100%" height="100%" />
						</Box>
						<Text flexGrow={1} my="6px" py={0} fontSize="sm">
							Odhlásit se
						</Text>
					</button>
				)}
			</Dialog>
		</Fragment>
	);
};
export default SideMenuStudent;
