/** @jsxImportSource @emotion/core */
import { css, SerializedStyles } from '@emotion/core';
import '@reach/dialog/styles.css';
import Dialog from '@reach/dialog';
import React, { useState, Fragment, FC, ReactNode } from 'react';
import { MdClose } from 'react-icons/md';

import Paper from 'components/styled/Paper';
import { Flex } from 'components/styled';
import Button from 'components/styled/Button';
import Text from 'components/styled/Text';

import { Theme } from 'theme';

type Props = {
	label: string;
	control: (openModal: () => void) => ReactNode;
	children: (closeModal: () => void) => ReactNode;
	customCss?: (theme: Theme) => SerializedStyles;
	onDismiss?: () => void;
	//when true the dialog cant be closed on click away event
	modal?: boolean;
};

const ModalDialog: FC<Props> = ({
	label,
	control,
	children,
	customCss,
	onDismiss,
	modal,
}) => {
	const [showModal, setShowModal] = useState(false);
	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<Fragment>
			{control(openModal)}
			<Dialog
				isOpen={showModal}
				onDismiss={() => {
					onDismiss?.();
					if (!modal) {
						closeModal();
					}
				}}
				aria-label={label}
				css={
					customCss ??
					((theme: Theme) => css`
						padding: 0 !important;
						overflow: hidden;
						min-width: ${theme.breakpoints[0]};

						@media (max-width: ${theme.breakpoints[0]}) {
							width: 100% !important;
							min-width: unset;
						}
					`)
				}
			>
				{children(closeModal)}
			</Dialog>
		</Fragment>
	);
};

export default ModalDialog;

export const ModalDialogDefaultBody: FC<{
	title: string;
	closeModal: () => void;
}> = ({ title, closeModal, children }) => (
	<Paper>
		<Flex width={1} justifyContent="space-between" alignItems="center">
			<Text fontWeight="bold" fontSize="lg">
				{title}
			</Text>

			<Button
				variant="text"
				p={2}
				onClick={closeModal}
				css={css`
					&:hover {
						background-color: rgba(0, 0, 0, 0.05);
					}
				`}
			>
				<MdClose size={24} />
			</Button>
		</Flex>
		{children}
	</Paper>
);
