/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import '@reach/menu-button/styles.css';
import React from 'react';
import { Menu, MenuItem, MenuLink, MenuButton } from '@reach/menu-button';
import { noop } from 'lodash-es';
import { MdCheck, MdKeyboardArrowDown } from 'react-icons/md';
import { useQueryCache } from 'react-query';

import { Flex } from 'components/styled';
import NotificationItem from 'components/tiles/NotificationItem';
import { NavLink } from 'components/styled/Link';
import Text from 'components/styled/Text';
import Button from 'components/styled/Button';

import { NotificationsIcon } from 'assets';
import { Theme } from 'theme';

import {
	markAllNotificationRead,
	useNotificationsNewest,
	useNotificationUnreadCount,
} from 'api/notificationsApi';

import { MenuAvatar, MenuHeader, StyledMenuList } from './styled';

const NotificationsBadge: React.FC = () => {
	const response = useNotificationsNewest();
	const countResponse = useNotificationUnreadCount();

	const items = response.data?.items ?? [];
	const unreadCount = countResponse.data ?? 0;
	const cache = useQueryCache();

	return (
		<Menu>
			{({ isOpen }) => (
				<React.Fragment>
					<MenuAvatar
						as={MenuButton}
						aria-label="User Notifications"
						disabled={items.length <= 0}
						isOpen={isOpen}
						position="relative"
					>
						{unreadCount > 0 && (
							<Flex
								justifyContent="center"
								alignItems="center"
								size={14}
								bg="error"
								color="white"
								css={css`
									position: absolute;
									top: 4px;
									right: 4px;
									border-radius: 50%;
									font-size: 11px;
								`}
							>
								{unreadCount > 99 ? '*' : unreadCount}
							</Flex>
						)}
						<NotificationsIcon />
					</MenuAvatar>

					<StyledMenuList>
						<Flex
							width={1}
							justifyContent="space-between"
							alignItems="center"
							backgroundColor="primary"
						>
							<MenuItem as={MenuHeader} onSelect={noop}>
								Notifikace
							</MenuItem>
							{unreadCount > 0 && (
								<MenuItem
									as={Button}
									onSelect={async () => {
										await markAllNotificationRead();
										cache.invalidateQueries('notifications');
									}}
									fontSize="sm"
									fontWeight="bold"
									variant="primary"
								>
									<MdCheck size={18} />
									<Text ml={2}>Označit vše jako přečtené</Text>
								</MenuItem>
							)}
						</Flex>
						{items.map(item => (
							<MenuItem key={item.id} onSelect={noop}>
								<NotificationItem {...item} variant="header" />
							</MenuItem>
						))}

						<MenuLink
							as={NavLink}
							to="/dashboard/notifications"
							css={(theme: Theme) => css`
								&[data-reach-menu-item] {
									background: ${theme.colors.lightGrey};
									padding: 0;
									text-decoration: none;
								}
							`}
						>
							<Flex
								justifyContent="center"
								alignItems="center"
								color="primary"
								py={2}
							>
								<Text mr={1} fontWeight="bold">
									Zobrazit vše
								</Text>
								<MdKeyboardArrowDown size={20} />
							</Flex>
						</MenuLink>
					</StyledMenuList>
				</React.Fragment>
			)}
		</Menu>
	);
};

export default NotificationsBadge;
