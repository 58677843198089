import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
export const isValidBrowser = browser.satisfies({
	chrome: '>=69',
	firefox: '>=62',
	opera: '>=56',
	safari: '>=13',
	edge: '>=79',
	samsung_internet: '>=10.1',
});
