/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';
import { FieldMetaProps } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';

import { Box, Flex } from 'components/styled';
import LoaderSpin from 'components/loaders/LoaderSpin';

import { styled, Theme } from 'theme';

import ErrorFeedback from '../error/ErrorFeedback';

import { FocusStyle, InvertFocusStyle, OffscreenCSS } from 'theme/GlobalStyles';

export type TextInputProps = {
	label: string;
	labelType?: 'inline' | 'leftToInput' | 'aboveInput';
	required?: boolean;
	colorVariant?: 'inverted';
	hideLabelOnValue?: boolean;
	labelMinWidth?: string;

	error?: FieldMetaProps<string>['error'];
	touched?: boolean;

	iconLeft?: React.ReactElement;
	iconRight?: React.ReactElement;
	hideArrows?: boolean;

	loading?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const InputWrapper = styled(Flex)<{
	hasError?: boolean;
	inverted?: boolean;
}>`
	color: ${p => p.theme.colors.primary};
	border-bottom: 1px solid;
	padding: ${p => p.theme.space[1]}px 0;

	border-color: ${p =>
		// eslint-disable-next-line no-nested-ternary
		p.hasError
			? p.theme.colors.error
			: p.inverted
			? 'rgba(255,255,255,0.75)'
			: p.theme.colors.primary};

	&:focus-within {
		${p => (p.inverted ? InvertFocusStyle : FocusStyle(p.theme))}
	}
`;

export const Label = styled.label<
	SpaceProps & LayoutProps & { required?: boolean }
>`
	text-align: left;
	white-space: nowrap;
	${space}
	${layout}

	${p =>
		p.required &&
		css`
			&:after {
				content: '*';
				color: ${p.theme.colors.error};
			}
		`}
`;

export const InputCss = (theme: Theme) => css`
	flex: 1;
	width: 100%;
	border: 0;
	background-color: transparent;
	font-size: 18px;
	padding: 0 ${theme.space[1]}px;
	line-height: 1.5;

	&:disabled {
		color: ${theme.colors.darkerGrey};
	}

	&:focus {
		box-shadow: none;
	}
`;

export const Input = styled.input<{ hideArrows?: boolean }>`
	${p => InputCss(p.theme)}
	${p =>
		p.hideArrows &&
		css`
			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type='number'] {
				-moz-appearance: textfield;
			}
		`}
`;

const LabelTextInput: React.FC<Omit<TextInputProps, 'hideLabelOnValue'>> = ({
	id,
	value,
	label,
	required,
	error,
	touched,
	iconLeft,
	iconRight,
	loading,
	labelType,
	labelMinWidth = '110px',
	...inputProps
}) => (
	<Flex
		width={1}
		{...(labelType === 'leftToInput'
			? { alignItems: 'center' }
			: { flexDirection: 'column' })}
	>
		<Label
			htmlFor={id}
			pr={labelType === 'leftToInput' ? 4 : 0}
			minWidth={labelMinWidth}
			required={required}
		>
			{label}:
		</Label>

		<InputWrapper
			flexGrow={1}
			alignItems="center"
			hasError={!!error && touched}
		>
			{iconLeft && (
				<Box mr={2} flex={0}>
					{iconLeft}
				</Box>
			)}

			<Input {...inputProps} id={id} value={value} />

			{iconRight && (
				<Box minWidth="auto" ml={2}>
					{iconRight}
				</Box>
			)}

			{loading && (
				<Box minWidth="auto" ml={1}>
					<LoaderSpin size={23} />
				</Box>
			)}
		</InputWrapper>
	</Flex>
);

const InlineTextInput: React.FC<Omit<TextInputProps, 'labelType'>> = ({
	id,
	value,
	label,
	required,
	hideLabelOnValue = false,
	colorVariant,
	error,
	touched,
	iconLeft,
	iconRight,
	loading,
	...inputProps
}) => (
	<InputWrapper
		width={1}
		alignItems="center"
		hasError={!!error && touched}
		color="text"
		inverted={colorVariant === 'inverted'}
		css={
			colorVariant === 'inverted' &&
			css`
				background: white;
				border-radius: 25px;
				padding-left: 12px;
				padding-right: 12px;
			`
		}
	>
		{iconLeft && (
			<Box minWidth="auto" mr={2}>
				{iconLeft}
			</Box>
		)}

		<Label
			htmlFor={id}
			pr={2}
			css={[hideLabelOnValue && !isEmpty(value) && OffscreenCSS]}
			required={required}
		>
			{label}
		</Label>

		<Input {...inputProps} id={id} value={value} />

		{iconRight && (
			<Box minWidth="auto" ml={2}>
				{iconRight}
			</Box>
		)}

		{loading && (
			<Box minWidth="auto" ml={1}>
				<LoaderSpin size={23} />
			</Box>
		)}
	</InputWrapper>
);

const TextInput: React.FC<TextInputProps> = ({
	type = 'text',
	labelType = 'leftToInput',
	error,
	touched,
	...props
}) => {
	const inputProps = {
		type,
		labelType,
		error,
		touched,
		...props,
	};

	return (
		<Box width={1}>
			{labelType !== 'inline' && <LabelTextInput {...inputProps} />}

			{labelType === 'inline' && <InlineTextInput {...inputProps} />}

			{error && touched && <ErrorFeedback>{error}</ErrorFeedback>}
		</Box>
	);
};

export default TextInput;
