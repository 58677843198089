import {
	useState,
	useLayoutEffect,
	DependencyList,
	createContext,
} from 'react';

import { FloatingMenuProps } from './_typing';

export const DIRECTIONS = {
	up: 'column-reverse',
	down: 'column',
	left: 'row-reverse',
	right: 'row',
};

export const useFloatingMenuHeight = (
	floatingMenuId: string,
	deps: DependencyList = [],
) => {
	const [height, setHeight] = useState(0);

	useLayoutEffect(() => {
		const elem = document.getElementById(floatingMenuId);
		if (!elem) {
			return;
		}

		const updateHeightSize = () => {
			setHeight(
				document.getElementById(floatingMenuId)?.getBoundingClientRect()
					.height ?? 0,
			);
		};

		updateHeightSize();

		window.addEventListener('resize', updateHeightSize);

		const observer = new ResizeObserver(entries => {
			const entry = entries.find(e => e.target.id === floatingMenuId);
			if (!entry) {
				return;
			}
			updateHeightSize();
		});
		observer.observe(elem);

		return () => {
			observer.disconnect();
			window.removeEventListener('resize', updateHeightSize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps, floatingMenuId]);

	return height;
};

type State = Required<
	Pick<FloatingMenuProps, 'isOpen' | 'slideSpeed' | 'direction' | 'spacing'>
>;

export const FloatingMenuContext = createContext<State>(
	({} as unknown) as State,
);
